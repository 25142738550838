import React, { memo } from 'react'
import PropTypes from 'prop-types';

const LibInput = (props) => {
  const { extraclass, value, type, placeholder, inputRef } = props
  // const changeHandler = (e) => {
  //   changeevent(e.target.value)
  // }
  return (
    <input
      data-testid="text-input"
      className={`${extraclass} form-control border border-gray75 rounded
      w-full grow focus:outline-none text-gray-600 placeholder-gray30`}
      type={type ? type : 'text'}
      value={value}
      placeholder={placeholder ? placeholder : "Enter here"}
      {...props}
    />
  )
}

LibInput.propTypes = {
  extraclass: PropTypes.string,
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  // inputref: PropTypes.any,
}

export default memo(LibInput)




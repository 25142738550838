import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { getFullNameOfSender } from "@utils";
import { capitalizeHandler } from "@utils";
import Utils from "@utils";
import ImageLoader from "../../utils/ImageLoader";

const ChatBlock = ({ messageObj }) => {
  const { date_display, messages } = messageObj;
  const user = Utils.getCurrentUser();

  const BlockDisplay = (element) => {
    let isSender = element.sender_id === user.session_user_id;
    return (
      <div
        key={element.id}
        data-testid="chatBlock"
        className={`chat-message ${
          isSender
            ? "sender-message-card"
            : "recipient-message-card justify-end"
        }`}
      >
        {element.is_first_message ? (
          <div
            key={element.id}
            className="dateHeader overflow-hidden flex items-center justify-center font-semibold mb-3.5  text-xs text-gray70"
          >
            <span>
              {capitalizeHandler(element.first_message_sender_full_name)} has
              joined the chatroom
            </span>
          </div>
        ) : null}
        {/* {element.usedNameInGroupArr ?
          <div key={element.id} className='dateHeader overflow-hidden flex items-center justify-center font-semibold mb-3.5  text-xs text-gray70'>
            <span>{capitalizeHandler(element.first_message_sender_full_name)} has joined the chatroom</span>
          </div>
          : null
        } */}
        <div className={`flex items-end ${isSender ? "flex-row-reverse" : ""}`}>
          {/* <img
            data-testid="messengerImage"
            className={`messagerImage rounded-full image-30`}
            src={element?.sender_profile_image}
            alt="My profile"
            title={`Message from ${element.sender_id}`}
          /> */}
          <ImageLoader
            defaultImg="user"
            data-testid="messengerImage"
            className={`messagerImage rounded-full image-30`}
            src={element?.sender_profile_image}
            alt="My profile"
            title={`Message from ${element.sender_id}`}
          />

          <div
            className={`realChatBlock flex flex-col space-y-2 items-end ${
              isSender ? "mr-5" : "ml-5"
            }`}
          >
            {/* {JSON.stringify(element, null, 2)} */}
            <div className={`inline-block text-white messageContainer`}>
              <div
                className={`messageKnotch max-w-max py-2.5 px-3.6 rounded-xll oml:rounded-xxl
                        ${
                          isSender
                            ? "bg-chatroomSecond rounded-br-none"
                            : "bg-chatroomFirst rounded-bl-none"
                        }
                        ${isSender ? "text-white" : "text-b75"}`}
              >
                <div
                  data-testid="messengerTitle"
                  className="tracking-normal messageBody text-sm oml:text-sm font-medium mr-5 break-words"
                >
                  {element?.body}
                </div>
                <div
                  data-testid="chatTime"
                  className={`messengerInfo text-right text-xs font-semibold ml-5 mt-1.5 leading-3.1 ${
                    isSender ? "text-white" : "text-gray70"
                  }`}
                >
                  {getFullNameOfSender(element, isSender)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="dateHeader overflow-hidden flex items-center justify-center font-semibold text-xs text-gray70">
        {date_display}
      </div>
      {messages
        ?.map((element, index) => (
          <Fragment key={index}>{BlockDisplay(element)}</Fragment>
        ))
        .reverse()}
    </>
  );
};

ChatBlock.propTypes = {
  messageObj: PropTypes.any.isRequired,
};
export default ChatBlock;

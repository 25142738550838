import { Fragment, memo, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import Loading from "@components/Common/Loading";
// const people = [
//   { name: 'Wade Cooper' },
//   { name: 'Arlene Mccoy' },
//   { name: 'Devon Webb' },
//   { name: 'Tom Cook' },
//   { name: 'Tanya Fox' },
//   { name: 'Hellen Schmidt' },
// ]

const SelectBox = (props) => {
  const { options, onChange, value, className, displayName, displayAs, optionClassName, listClass, loadMoreData, isSelectLoading = false, isLoading = false } = props;
  // const [open, setOpen] = useState(false)

  const SelectHandler = (val) => {
    onChange(val);
  }

  // const blurHandler = () => {
  //   setOpen(false)
  // }

  return (
    <Listbox value={displayName} onChange={SelectHandler}>
      <div className={`relative ${className}`}>
        {displayAs === "button" ?
          <Listbox.Button className="
          form-control select-box
          relative cursor-pointer text-white rounded bg-green py-2 px-3 text-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
            <span className="block truncate text-xs font-semibold mmd:text-sm">{displayName}</span>
          </Listbox.Button>
          :
          <Listbox.Button className="
          form-control border border-gray75 rounded
          relative w-full cursor-default bg-white py-2 pl-3 pr-10 text-left focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
            <span className="block truncate">{displayName}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <img src={`/images/icons/chevron-down.svg`} alt="UP" width={12} height={7} />
            </span>
          </Listbox.Button>
        }
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className={`${listClass} absolute z-10 max-h-60 w-full overflow-auto rounded bg-secondary text-base mt-1 focus:outline-none`}>
            <InfiniteScroll
              initialLoad={false}
              loadMore={loadMoreData}
              hasMore={isSelectLoading}
              loader={isLoading && <Loading />}
              useWindow={false}
            >
              {options.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `relative select-none py-3 px-4 text-white cursor-pointer ${active ? 'bg-primary' : ''
                    }`
                  }
                  value={person}
                >
                  {({ displayName }) => (
                    <>
                      <span className={`${optionClassName} block truncate ${displayName ? 'font-medium' : 'font-normal'}`}>
                        {person.name}
                      </span>
                      {/* {displayName ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        *
                      </span>
                    ) : null} */}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </InfiniteScroll>
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

SelectBox.prototype = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  optionClassName: PropTypes.string,
  listClass: PropTypes.string,
  displayName: PropTypes.string,
  displayAs: PropTypes.bool,
  isSelectLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadMoreData: PropTypes.func
}

export default memo(SelectBox)
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  teamsArr: [],
  selectedTeam: {},
  selectedTeamMembers: [],
  isLoading: false,
  hasMore: false,
  pagination: {},
};

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setTeams(state, action) {
      state.teamsArr = action.payload;
    },
    setSelectedTeam(state, action) {
      state.selectedTeam = action.payload;
    },
    setSelectedTeamMembers(state, action) {
      state.selectedTeamMembers = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setHasMore(state, action) {
      state.hasMore = action.payload;
    },
    setPagination(state, action) {
      state.pagination = action.payload;
    },

  },
})

//named export
export const teamsActions = teamsSlice.actions;

//default export
export default teamsSlice;
import API from '@lib/api';
import { PortalType } from '..';
import { showToastify } from '@utils';
const SERVICE_NAME = `${PortalType}/customers`;

let CustomerService = {
  getCustomers: (pageNo) => {
    return API.get(`${SERVICE_NAME}?page=${pageNo}`)
      .then((response) => {
        console.log("===load Customers===", response)
        return response;
      })
      .catch((error) => {
        console.error("=========getCustomers error===", error);
      });
    },
    createCustomerChatroom: (selectedCustomer) => {
      return API.post(`${SERVICE_NAME}/${selectedCustomer}/create_chatroom`)
      .then((response) => {
        console.log("==Customer chatroom created====", response)
        return response;
      })
      .catch((error) => {
        console.error("=========createCustomerChatroom error ===", error);
      });
  },
}


export default CustomerService;


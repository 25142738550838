import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userActions } from "@store/user-slice";
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import AppRoutes from "@routes";
import Navbar from "@components/ExtraComponent/Navbar";
import SessionService from "@services/common/sessions/SessionsService";
import { applyTheme } from "@theme";
import 'react-toastify/dist/ReactToastify.css';

function App({ cable }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  const navigate = useNavigate();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const uuid = params.get("uuid");
  const type = params.get("type");

  const [isSessionSet, setSession] = useState(false);

  const loadChat = () => {
    //TODO
    // navigate('/my-chats', { state: { session: session } });
    //keey the uuid for future use, if any in the url itself
    navigate(`/my-chats?uuid=${uuid}&type=${type}`);
    // setSearchParams({uuid});
  };

  const setLayoutType = (type) => {
    localStorage.setItem('userType', type);
  }

  //only keeping token for client server authentication.
  //dont forget to remove this on logout, if exists
  // const setLocalStorage = (token) => {
  //   localStorage.removeItem("token");
  //   localStorage.setItem("token", token)
  // }

  // useEffect(() => {
  //   localStorage.setItem('userType', type);
  // }, [dispatch])

  // theme mount
  useEffect(() => {
    if (type) {
      applyTheme(type);
    }
  }, [type]);

  //Call only once during page load
  useEffect(() => {
    if (uuid) {
      const data = {
        uuid,
        type
      };

      SessionService.postSession(data).then(async (r) => {
        if (r?.status === 200) {
          await dispatch(userActions.setUser(r.data));
          await dispatch(userActions.setVisitedPortal(type));
          await dispatch(userActions.setLocalToken(r.data.user.session));
          setSession(true)
          // setLocalStorage(r.data.user.session)
          setLayoutType(r.data.user.session_user_type)
          loadChat()
        }
      });
    } else {
      setSession(false);
    }
  }, []);

  //watch loading
  useEffect(() => {
    setTimeout(() => {
      if (isLoading) {
        setLoading(false);
      }
    }, 1000);
  }, [isLoading]);

  return (
    <div id="supperContainer" className="theme-customer">
      <Navbar />
      {/* dynamic rendering */}
      {(() => {
        if (isLoading) {
          return (
            <p data-testid="text" className="w-full h-full w-max-7xl flex flex-center text-3xl text-indigo-400 items-center w-100 text-center flex-auto justify-center py-6">
              Loading Chats...
            </p>
          );
        } else if (!isLoading && !isSessionSet) {
          return (
            <p className="w-full h-full w-max-7xl flex flex-center text-3xl text-red-400 items-center w-100 text-center flex-auto justify-center py-6">
              Error Loading Chats.Try again.
            </p>
          );
        } else {
          return (
            <AppRoutes cable={cable} />
          );
        }
      })()}
      <ToastContainer
        theme="colored"
        position="top-center"
        className="toast-container"
        toastClassName="dark-toast"
        
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        // autoClose={3000}
        // closeOnClick
        // pauseOnHover
      />
    </div>
  );
}

export default App;

/* eslint-disable array-callback-return */
import React, { Fragment, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import LibIconTextButton from "@components/Common/Button/LibIconTextButton";
import { layoutActions } from "@store/layout-slice";
import { useDispatch } from "react-redux";
import LibIconButton from '@components/Common/Button/LibIconButton';
import Loading from "@components/Common/Loading";
import Utils from "@utils";
import { capitalizeHandler } from "@utils";
import useDimensions from "@utils/useDimensions";
import { isArray } from "lodash";
import { defaultImageLoad } from "../../../utils";
// import Envelope from "@components/Common/Icons/Envelope";
// import Phone from "@components/Common/Icons/Phone";
function FacilityInfo({ deleteGroupChatRoom }) {
  const dispatch = useDispatch()
  const dimentions = useDimensions();
  const chatroomDetail = Utils.getCurrentChatroomDetail();
  const currentChatroom = Utils.getCurrentChatroom();
  const isChatroomLoading = Utils.getCurrentChatroomLoading();
  const [listData, setListData] = useState([])
  const isChatroomCreatedByUser = Utils.getIsChatroomCreatedByUser();
  const user = Utils.getCurrentUser();

  const setSidebarOpen = () => {
    dispatch(layoutActions.setRightBar(false))
  }

  useEffect(() => {
    if (isArray(chatroomDetail?.metadata?.company_detail)) {
      setListData(chatroomDetail?.metadata?.company_detail)
    }
    return (() => setListData([]))
  }, [chatroomDetail])

  return (
    <div className="flex w-full flex-col h-full chat-lists overflow-hidden">
      <div className="listTeamsHeader w-full mb-8 hidden lg:flex ">
        <div className="flex w-full items-center justify-between">
          <h3 className="text-lg font-medium text-white lg:text-b900">
            Facility Information
          </h3>
          <LibIconButton
            extraclass={'bg-lb iconButton-35 rounded'}
            icon={'close.svg'}
            text={'close'}
            btntype='default'
            onClick={() => setSidebarOpen()}
            iconsize={20}
          />
        </div>
      </div>

      {/* {JSON.stringify(chatroomDetail)} */}
      {(() => {
        if (isChatroomLoading) {
          return <Loading />
        } else {
          return (
            <Fragment>
              <span className="darkbackforMobile bg-secondary lg:hidden flex absolute inset-0 bottom-auto z-0"></span>
              <div className="flex w-full relative lg:flex-col flex-col-reverse h-full chat-lists overflow-hidden">
                <div className="flex-1 flex flex-col team-lists-container overflow-hidden h-full pt-16 lg:pt-0">
                  <div className="infoHeader mb-8">
                    <div className="clubLogo">
                      {/* Not getting image use this code */}
                      {chatroomDetail?.chatroom_profile_picture ? <img className="image rounded-full server-image" src="/images/epic_games.png" alt="club name" /> :
                        <div className="bg-secondary rounded-full static-info-icon flex justify-center items-center">
                          <img data-testid="currentRecieverImage"
                            className="w-11 h-11"
                            aria-hidden
                            src={`/images/icons/${defaultImageLoad(currentChatroom, user)}`}
                            alt="header-logo" />
                        </div>}
                    </div>
                    <div className="clubName text-xl font-medium text-center leading-6">
                      {capitalizeHandler(chatroomDetail?.title)}
                    </div>
                  </div>

                  <div className="clubContactInfo flex flex-1 flex-col space-y-4 overflow-auto">
                    {listData.map((elem, index) => {
                      return (
                        <div className="text-b900 facilityInfo rounded-md bg-lb lg:bg-mainbgColor flex-col flex gap-2.5 p-4 osm:p-3" key={index}>
                          <div className="fi_name text-xl font-medium block truncate leading-6">
                            {capitalizeHandler(elem?.facility_name)}
                          </div>
                          {elem?.full_address && <div className="fi_address text-xs font-medium text-b400 block truncate">
                            {elem?.full_address}
                          </div>}
                          <div className="fi_email flex items-center gap-3.5">
                            <span className="icon">
                              <span className="fixIcon envelop rounded w-[1.8rem] h-[1.8rem] bg-primary">
                                <img src="/images/icons/envelope.svg" alt="Phone" className="w-[15px] h-[15px]" />
                              </span>
                            </span>
                            <span className="fi_email_text text-sm font-medium text-b900 block truncate">
                              {elem.email ? elem?.email : 'Not available'}
                            </span>
                          </div>
                          <div className="fi_phone  flex items-center gap-3.5">
                            <span className="icon">
                              <span className="fixIcon phone rounded w-[1.8rem] h-[1.8rem] bg-primary">
                                <img src="/images/icons/phone.svg" alt="Phone" className="w-[15px] h-[15px]" />
                              </span>
                            </span>
                            <span className="fi_phone_text text-sm font-medium text-b900">
                              {elem?.phone ? elem?.phone : 'Not available'}
                            </span>
                          </div>
                        </div>)
                    })}
                  </div>

                </div>
                <div className="listTeamsFooter facilityInfo">
                  <div className="flex items-center justify-between lg:justify-end ">
                    <LibIconTextButton
                      extraclass={'lg:hidden text-white'}
                      icon={'back-arrow-white.svg'}
                      text={'Back'}
                      btntype={'plain'}
                      labeltext={'Back'}
                      labelClass='!text-lg'
                      onClick={setSidebarOpen}
                      iconsize={15}
                    />
                    {isChatroomCreatedByUser && <LibIconTextButton
                      extraclass={'text-white lg:text-b75 !gap-1'}
                      icon={(dimentions?.width > 1024) ? 'bin-black.svg' : 'bin-white.svg'}
                      text={'Delete Chat'}
                      btntype={'plain'}
                      labeltext={'Delete chat'}
                      labelClass='!font-semibold'
                      iconsize={21}
                      disabled={!isChatroomCreatedByUser}
                      onClick={deleteGroupChatRoom}
                    />}
                  </div>
                </div>
              </div>
            </Fragment>
          )
        }
      })()}
    </div>
  );
}

FacilityInfo.propTypes = {
  deleteGroupChatRoom: PropTypes.func.isRequired
}


export default FacilityInfo;

import React, { Fragment, lazy, Suspense } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import BackgroundLayer from "@components/Common/Dialogue/Transitions/BackgroundLayer";
import { layoutActions } from "@store/layout-slice";
import { includes } from "lodash";
import { chatroomActions } from "@store/chatroom-slice";
import Loading from "@components/Common/Loading";

const NewChatCustomer = lazy(() => import("@components/NewChatCustomer"));
const NewChatClub = lazy(() => import("@components/NewChatClub"));
const NewChatGroup = lazy(() => import("@components/NewChatGroup"));
const NewChatTeam = lazy(() => import("@components/NewChatTeam"));
const NewChatFacility = lazy(() => import("@components/NewChatFacility"));

const RightDialogueLg = () => {
  const dispatch = useDispatch();
  const portalType = ['club', 'facility']

  const { newChatPopup, chatByPortal } = useSelector((state) => ({ ...state.layout, shallowEqual }))

  const setListbarClose = () => {
    dispatch(layoutActions.setNewChatPopup(false))
    dispatch(layoutActions.setChatByPortal({}));
    dispatch(layoutActions.setGroupTogglerType({}));
  }

  return (
    <Transition.Root id="newChatDialogue" show={newChatPopup} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40 right-0"
        onClose={setListbarClose}>

        <BackgroundLayer />

        <div className={`sidebarRightInner fixed inset-0 z-45 flex justify-end left-auto ${['group', 'team'].includes(chatByPortal.value) ? 'w-5/14 omd:w-6/12' : 'w-6/12'} osm:w-full`}>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-full"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-full"
            leaveTo="translate-x-full"
          >
            <Dialog.Panel className={`relative flex w-full flex-1 flex-col bg-white focus:outline-none 
            max-w-screen-${includes(portalType, chatByPortal.value) ? 'lg' : 'md'}
            `}>
              <div className={`relative flex flex-1 w-full flex-shrink-0 lg:pb-7 pb-5 lg:px-[2.143rem] overflow-hidden ${chatByPortal.value === 'customer' ? 'mmd:px-0' : 'px-5'}`}>
                <div className="flex-1 overflow-hidden z-10 flex flex-col">
                  {(chatByPortal.value === 'club') ?
                    <NewChatClub
                      setListbarClose={setListbarClose}
                    />
                    : null}

                  {(chatByPortal.value === 'facility') ?
                    <NewChatFacility
                      setListbarClose={setListbarClose}
                    />
                    : null}

                  {(chatByPortal.value === 'group') ?
                    <NewChatGroup
                      setListbarClose={setListbarClose}
                    />
                    : null
                  }

                  {(chatByPortal.value === 'customer') ?
                    <NewChatCustomer
                      setListbarClose={setListbarClose}
                    />
                    : null
                  }

                  {(chatByPortal.value === 'team') ?
                    <NewChatTeam
                      setListbarClose={setListbarClose}
                    />
                    : null
                  }
                </div>
              </div>

            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}


RightDialogueLg.propTypes = {

}

export default RightDialogueLg
import API from '@lib/api';
import { PortalType } from "..";
const SERVICE_NAME = `${PortalType}/customers`;

let CustomerService = {
  getCustomers: (pageNo) => {
    return API.get(`${SERVICE_NAME}?page=${pageNo}`)
      .then((response) => {
        console.log("===load getCustomers===", response)
        return response;
      })
      .catch((error) => {
        console.error("=========getCustomers error===", error);
      });
  },
  createCustomerChatroom: (selectedFacility) => {
    return API.post(`${SERVICE_NAME}/${selectedFacility}/create_chatroom`)
      .then((response) => {
        console.log("==Customer Facility Chatroom created====", response)
        return response;
      })
      .catch((error) => {
        console.error("=========createCustomersChatroom error ===", error);
      });
  },
}


export default CustomerService;


import React from 'react'
import PropTypes from 'prop-types';

const LibIconTextButton = ({ extraclass, icon, text, btntype, iconsize, labeltext, labelClass, ...props }) => {

  return (
    <button
      data-testid="button-icontext"
      className={`btn border-none inline-flex gap-2 items-center justify-center transition duration-500 ease-in-out ${extraclass} bg-${btntype ? btntype : 'default'}`}
      {...props}
    >
      <img src={`/images/icons/${icon}`} alt={text} width={iconsize} height={iconsize} />
      <span className={`${labelClass} font-medium text-sm text-inherit`}>{labeltext}</span>
    </button>
  )
}

LibIconTextButton.propTypes = {
  iconsize: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  labeltext: PropTypes.string,
  text: PropTypes.string.isRequired,
  extraclass: PropTypes.string.isRequired,
  btntype: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
}

export default LibIconTextButton


{/* <LibIconTextButton
  extraclass={'rounded-full h-10 w-10 '}
  icon={'emoji-smile.svg'}
  text={'Smile'}
  
  <LibIconButton
  size={20}
  onClick={() => showDetail()}
/> */}
import React, { Fragment, lazy, Suspense } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import BackgroundLayer from "@components/Common/Dialogue/Transitions/BackgroundLayer";
import { layoutActions } from "@store/layout-slice";
import { includes } from "lodash";
import Loading from "@components/Common/Loading";

const AddNewMembers = lazy(() => import("@components/NewChatGroup/AddNewMembers"));
const RemoveMembers = lazy(() => import("@components/NewChatGroup/RemoveMembers"));

const RightDialogueLgGroup = () => {
  const dispatch = useDispatch();
  const portalType = ['club', 'facility']

  const { groupTogglerType, chatByPortal, groupTogglerPopup } = useSelector((state) => ({ ...state.layout, shallowEqual }))

  const setListbarClose = () => {
    dispatch(layoutActions.setGroupTogglerPopup(false))
    dispatch(layoutActions.setChatByPortal({}));
    dispatch(layoutActions.setGroupTogglerType({}));
  }

  return (
    <Transition.Root id="rightDialogueLgGroup" show={groupTogglerPopup} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40 right-0"
        onClose={setListbarClose}>
        {/* layer */}
        <BackgroundLayer />
        <div className={`sidebarRightInner fixed inset-0 z-45 flex justify-end left-auto ${['group', 'team'].includes(chatByPortal.value) ? 'w-5/14 omd:w-6/12' : 'w-6/12'} osm:w-full`}>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-full"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-full"
            leaveTo="translate-x-full"
          >
            <Dialog.Panel className={`relative flex w-full flex-1 flex-col bg-white focus:outline-none 
            max-w-screen-${includes(portalType, chatByPortal.value) ? 'lg' : 'md'}
            `}>
              <div className="relative flex flex-1 w-full flex-shrink-0 pb-5 px-5 lg:pb-7 lg:px-[2.143rem] overflow-hidden">
                <div className="flex-1 overflow-hidden z-10 flex flex-col">

                  {(groupTogglerType?.value === 'add_member') ?
                    <AddNewMembers
                      setListbarClose={setListbarClose}
                    />
                    : null
                  }

                  {(groupTogglerType?.value === 'remove_member') ?
                    <RemoveMembers
                      setListbarClose={setListbarClose}
                    />
                    : null
                  }

                </div>
              </div>

            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}


RightDialogueLgGroup.propTypes = {

}

export default RightDialogueLgGroup
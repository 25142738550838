/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-undef */
import {
  filter,
  get,
  map,
  includes,
  isArray,
  isEmpty,
  isObject,
  isUndefined,
  reduce,
  startCase,
  toLower,
  capitalize,
} from "lodash";
import { useSelector } from "react-redux";

import ClubUserContactsService from "@services/club/customer/ContactsService";
import ClubUserCustomerService from "@services/club/customer/CustomerService";
import UserContactsService from "@services/user/customer/ContactsService";
import UserCustomerService from "@services/user/customer/CustomerService";

import ClubUserFacilityService from "@services/club/facility/FacilityService";
import CustomerFacilityService from "@services/customer/facility/FacilityService";

import UserClubService from "@services/user/club/ClubService";
import CustomerClubService from "@services/customer/club/ClubService";

import ClubUserTeamService from "@services/club/team/TeamService";
import UserTeamService from "@services/user/team/TeamService";
import CustomerTeamService from "@services/customer/team/TeamService";

export default {
  validateEmail: (emailAdress) => {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emailAdress.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  },

  getCurrentUser: () => {
    const userState = useSelector((state) => state);
    return !isUndefined(get(userState, "user", "user"))
      ? userState.user.user.user
      : null;
  },

  getAllChatrooms: () => {
    const chatroomState = useSelector((state) => state);
    return !isUndefined(get(chatroomState, "chatroom", "all_chatrooms"))
      ? chatroomState.chatroom.all_chatrooms
      : null;
  },

  getCurrentChatroom: () => {
    const chatroomState = useSelector((state) => state);
    return !isUndefined(get(chatroomState, "chatroom", "current_chatroom"))
      ? chatroomState.chatroom.current_chatroom
      : null;
  },
  getChatroomsTeam: () => {
    const chatroomState = useSelector((state) => state);
    return !isUndefined(get(chatroomState, "chatroom", "chatrooms_team"))
      ? chatroomState.chatroom.chatrooms_team
      : null;
  },
  getChatroomsDirect: () => {
    const chatroomState = useSelector((state) => state);
    return !isUndefined(get(chatroomState, "chatroom", "chatrooms_direct"))
      ? chatroomState.chatroom.chatrooms_direct
      : null;
  },
  getCurrentChatroomDetail: () => {
    const chatroomState = useSelector((state) => state);
    return !isUndefined(
      get(chatroomState, "chatroom", "current_chatroom_detail")
    )
      ? chatroomState.chatroom.current_chatroom_detail
      : null;
  },
  getCurrentChatroomLoading: () => {
    const chatroomState = useSelector((state) => state);
    return !isUndefined(get(chatroomState, "chatroom", "isChatroomLoading"))
      ? chatroomState.chatroom.isChatroomLoading
      : null;
  },
  getSelectedTeam: () => {
    const chatroomState = useSelector((state) => state);
    return !isUndefined(get(chatroomState, "chatroom", "selectedTeam"))
      ? chatroomState.chatroom.selectedTeam
      : null;
  },
  getIsHaveNewMessageDirect: () => {
    const chatroomState = useSelector((state) => state);
    return !isUndefined(
      get(chatroomState, "chatroom", "is_have_new_message_direct")
    )
      ? chatroomState.chatroom.is_have_new_message_direct
      : null;
  },
  getIsHaveNewMessageTeam: () => {
    const chatroomState = useSelector((state) => state);
    return !isUndefined(
      get(chatroomState, "chatroom", "is_have_new_message_team")
    )
      ? chatroomState.chatroom.is_have_new_message_team
      : null;
  },
  getIsChatroomCreatedByUser: () => {
    const chatroomState = useSelector((state) => state);
    return !isUndefined(
      get(chatroomState, "chatroom", "isChatroomCreatedByUser")
    )
      ? chatroomState.chatroom.isChatroomCreatedByUser
      : null;
  },
  getIsCurrentChatroomDeleted: () => {
    const chatroomState = useSelector((state) => state);
    return !isUndefined(
      get(chatroomState, "chatroom", "isCurrentChatroomDeleted")
    )
      ? chatroomState.chatroom.isCurrentChatroomDeleted
      : null;
  },
  getIsCurrentChatroomParticipantsDeleted: () => {
    const chatroomState = useSelector((state) => state);
    return !isUndefined(
      get(chatroomState, "chatroom", "isCurrentChatroomParticipantsDeleted")
    )
      ? chatroomState.chatroom.isCurrentChatroomParticipantsDeleted
      : null;
  },
  getRealTimeChatroomMessages: () => {
    const chatroomState = useSelector((state) => state);
    return !isUndefined(
      get(chatroomState, "chatroom", "realTimeChatroomMessages")
    )
      ? chatroomState.chatroom.realTimeChatroomMessages
      : null;
  },
  getRealTimeParticipantsMessages: () => {
    const chatroomState = useSelector((state) => state);
    return !isUndefined(
      get(chatroomState, "chatroom", "realTimeParticipantsMessages")
    )
      ? chatroomState.chatroom.realTimeParticipantsMessages
      : null;
  },
  getChatByPortal: () => {
    const layoutState = useSelector((state) => state);
    return !isUndefined(get(layoutState, "layout", "chatByPortal"))
      ? layoutState.layout.chatByPortal
      : null;
  },
  getCurrentActiveChatTab: () => {
    const layoutState = useSelector((state) => state);
    return !isUndefined(get(layoutState, "layout", "currentActiveChatTab"))
      ? layoutState.layout.currentActiveChatTab
      : null;
  },
  getMessagesHistory: () => {
    const messageState = useSelector((state) => state);
    return !isUndefined(get(messageState, "messages", "messagesHistory"))
      ? messageState.messages.messagesHistory
      : null;
  },
  getTeams: () => {
    const teamsState = useSelector((state) => state);
    return !isUndefined(get(teamsState, "teams", "teamsArr"))
      ? teamsState.teams.teamsArr
      : null;
  },
  getSelectedTeam: () => {
    const teamsState = useSelector((state) => state);
    return !isUndefined(get(teamsState, "teams", "selectedTeam"))
      ? teamsState.teams.selectedTeam
      : null;
  },
  getSelectedTeamMembers: () => {
    const teamsState = useSelector((state) => state);
    return !isUndefined(get(teamsState, "teams", "selectedTeamMembers"))
      ? teamsState.teams.selectedTeamMembers
      : null;
  },
  getIsLoading: () => {
    const teamsState = useSelector((state) => state);
    return !isUndefined(get(teamsState, "teams", "isLoading"))
      ? teamsState.teams.isLoading
      : null;
  },
  getHasMore: () => {
    const teamsState = useSelector((state) => state);
    return !isUndefined(get(teamsState, "teams", "hasMore"))
      ? teamsState.teams.hasMore
      : null;
  },
  getPagination: () => {
    const teamsState = useSelector((state) => state);
    return !isUndefined(get(teamsState, "teams", "pagination"))
      ? teamsState.teams.pagination
      : null;
  },
};

export const filterTableRowHandler = (filterText, elemObj) => {
  if (
    Object.values(elemObj)
      .join()
      .toUpperCase()
      .includes(filterText.toUpperCase())
  ) {
    return true;
  }
  return false;
};

export const capitalizeHandler = (str, isSender) => {
  return startCase(toLower(str));
};

export const getFullNameOfSender = (messageObj, isSender) => {
  let nameStr = "";

  const noNameNeedfor = ["ClubContact"];

  if (!includes(noNameNeedfor, messageObj.sender_type)) {
    if (messageObj?.sender_belongs_to) {
      nameStr += messageObj?.sender_belongs_to + " ";
    }

    if (messageObj?.sender_belongs_to) {
      nameStr += isSender
        ? "(You) "
        : `(${capitalizeHandler(messageObj?.sender_fullname)})  `;
    } else {
      nameStr += isSender
        ? "You "
        : `${capitalizeHandler(messageObj?.sender_fullname)} `;
    }
  }
  nameStr += messageObj?.created_at_time;

  return nameStr;
};

export const getFullNameOfLastSender = (element, user) => {
  let nameWithMsz = "";
  if (isEmpty(element?.firstMessage)) {
    if (includes(["Group", "Team"], element?.chatroom_owner)) {
      // if (element?.chatroom_creator_id === user.session_user_id) {
      if (element?.last_message_sender_uuid === user.session_user_uuid) {
        nameWithMsz += "You: ";
      } else {
        if (element?.last_message_sent_by) {
          nameWithMsz += capitalize(element?.last_message_sent_by) + ": ";
        }
      }
    }
    nameWithMsz += element?.last_message ? element?.last_message : "";
  } else {
    nameWithMsz += element?.firstMessage ? element?.firstMessage : "";
  }

  return nameWithMsz;

  // OLD SETUP
  // if (isEmpty(element?.firstMessage)) {
  // 	if (!includes(['Customer', 'Contact', 'User'], element?.chatroom_owner)) {
  // 		if (element?.chatroom_owner_id === user.session_user_id) {
  // 			nameWithMsz += "You: "
  // 		} else {
  // 			if (element?.last_message_sent_by) {
  // 				nameWithMsz += capitalize(element?.last_message_sent_by) + ': '
  // 			}
  // 		}
  // 	}
  // 	nameWithMsz += element?.last_message
  // } else {
  // 	nameWithMsz += element?.firstMessage;
  // }
};

export const isoStringToTime = (isoTime) => {
  const time = new Date(isoTime).toLocaleTimeString("en", {
    timeStyle: "short",
    hour12: false,
    timeZone: "UTC",
  });

  let hours = parseInt(isoTime.substring(0, 2), 10);
  let ampm = "AM";
  // minutes = isoTime.substring(3, 5),

  if (hours >= 12) {
    ampm = "PM";
  }
  return ` ${time} ${ampm} `;
};

// export const messageHistoryDate = (isoTime) => {
// 	const otherDate = new Date(isoTime)
// 	const todayDate = new Date()

// 	if (
// 		otherDate.getDate() === todayDate.getDate() &&
// 		otherDate.getMonth() === todayDate.getMonth() &&
// 		otherDate.getYear() === todayDate.getYear()
// 	) {
// 		return "TODAY";
// 	} else {
// 		return otherDate.toLocaleDateString('en', { weekday: "long", year: "numeric", month: "short", day: "numeric" });
// 	}
// }

export const pushNewMessageInLast = (ALLMessage, newMessage) => {
  const oldMessage = JSON.parse(JSON.stringify(ALLMessage));

  if (!isEmpty(oldMessage)) {
    // WHEN LAST MESSAGE GROUP IS SAME DAY
    const otherDate = oldMessage[oldMessage.length - 1][0];
    const todayDate = newMessage.created_at_date;
    if (otherDate === todayDate) {
      oldMessage[oldMessage.length - 1][1].push(newMessage);
      return oldMessage;
    }
    return [...oldMessage, [newMessage.created_at_date, [newMessage]]];
  } else {
    return [...oldMessage, [newMessage.created_at_date, [newMessage]]];
  }
};

export const CustomerServiceFromType = (type) => {
  const services = {
    ClubUser: ClubUserCustomerService,
    User: UserCustomerService,
  };
  return services[type];
};

export const ContactsServiceFromType = (type) => {
  const services = {
    ClubUser: ClubUserContactsService,
    User: UserContactsService,
  };
  return services[type];
};

export const FacilityServiceFromType = (type) => {
  const services = {
    ClubUser: ClubUserFacilityService,
    Customer: CustomerFacilityService,
  };
  return services[type];
};

export const ClubServiceFromType = (type) => {
  const services = {
    User: UserClubService,
    Customer: CustomerClubService,
  };
  return services[type];
};

export const TeamServiceFromType = (type) => {
  const services = {
    ClubUser: ClubUserTeamService,
    User: UserTeamService,
    Customer: CustomerTeamService,
  };
  return services[type];
};

export const showToastify = (messages, type) => {
  // "linear-gradient(to right, #00b09b, #96c93d)",
  const background = {
    success: "#CEF2DA",
    error: "#FFDCDC",
  };
  const color = {
    success: "#0C611A",
    error: "#FF3A3A",
  };

  const showToastHandler = (message) => {
    window
      .Toastify({
        text: message,
        position: "center",
        style: {
          background: background[type],
          color: color[type],
        },
        duration: 3000,
      })
      .showToast();
  };

  if (!isEmpty(messages)) {
    if (isArray(messages)) {
      messages.forEach((message) => {
        showToastHandler(message);
      });
    } else if (isObject(messages)) {
      showToastHandler(JSON.stringify(messages));
    } else {
      showToastHandler(messages);
    }
  } else {
    showToastHandler("Something is wrong!");
  }
};

// NOT TO BE DELETE IT
// TODO this function use for set already created chatroom data
export const getAlreadyUsedChatroomIds = (
  allChatroomList,
  currentData,
  filterKey
) => {
  // const filterKeys = {
  // 	"club": 'club',
  // 	"facility": 'facility'
  //  "team": 'team'
  // }
  const data = filter(
    allChatroomList,
    (el) => el.uuid.split("_")[1] === filterKey.value
  );

  const idsArr = reduce(
    data,
    (a, v) => {
      if (!includes(a, v.chatroom_owner_id)) {
        a = [...a, v.chatroom_owner_id];
      }
      return a;
    },
    []
  );
  // [...new Map(currentData.map(item => [item['id'], item])).values()],
  const updateArr = map(currentData, (el) => {
    if (includes(idsArr, el.id)) {
      return (el = { ...el, is_chatroom_created: true });
    } else {
      return (el = { ...el, is_chatroom_created: false });
    }
  });

  return updateArr;
};

export const defaultImageLoad = (currentChatroom, user) => {
  let type = chatroomReactType(currentChatroom, user);
  switch (type) {
    case "CUSTOMERINFO":
      // case 'CUSTOMER':
      // case 'USER':
      // case 'CLUBCONTACT':
      // case 'CLUBUSER':
      return "user.png";
    case "CLUBINFO":
      // case 'CLUBCOMPANY':
      return "shield-with-queen.png";
    case "FACILITYINFO":
      // case 'COMPANY':
      return "building.png";
    case "GROUPINFO":
    case "TEAMINFO":
      return "group-image.png";
    default:
      return null;
  }
};

export const chatroomReactType = (currentChatroom, user) => {
  if (
    includes(
      ["ClubCompany", "Company", "ClubContact", "ClubUser", "Customer", "User"],
      currentChatroom?.chatroom_owner
    )
  ) {
    switch (user?.session_user_type) {
      case "Customer":
        if (
          (currentChatroom.chatroom_creator === "Customer" &&
            currentChatroom.chatroom_owner === "ClubCompany") ||
          (currentChatroom.chatroom_creator === "ClubUser" &&
            currentChatroom.chatroom_owner === "Customer")
        ) {
          return "CLUBINFO";
        } else if (
          (currentChatroom.chatroom_creator === "Customer" &&
            currentChatroom.chatroom_owner === "Company") ||
          (currentChatroom.chatroom_creator === "User" &&
            currentChatroom.chatroom_owner === "Customer")
        ) {
          return "FACILITYINFO";
        } else {
          return "CUSTOMERINFO";
        }
      case "ClubUser":
        if (
          (currentChatroom.chatroom_creator === "User" &&
            currentChatroom.chatroom_owner === "ClubCompany") ||
          (currentChatroom.chatroom_creator === "ClubUser" &&
            currentChatroom.chatroom_owner === "Company")
        ) {
          return "FACILITYINFO";
        } else {
          return "CUSTOMERINFO";
        }
      case "User":
        if (
          (currentChatroom.chatroom_creator === "User" &&
            currentChatroom.chatroom_owner === "ClubCompany") ||
          (currentChatroom.chatroom_creator === "ClubUser" &&
            currentChatroom.chatroom_owner === "Company")
        ) {
          return "CLUBINFO";
        } else {
          return "CUSTOMERINFO";
        }
      default:
        return null;
    }
  } else if ("Group" === currentChatroom?.chatroom_owner) {
    return "GROUPINFO";
  } else if ("Team" === currentChatroom?.chatroom_owner) {
    return "TEAMINFO";
  }

  return false;
};

export const isMemberLogedin = (webHookData, currentUser) => {
  return webHookData?.data?.customer?.id === currentUser?.session_user_id;
};

export const isPrimaryChatroomCreatedByThisTeam = (
  webHookData,
  currentChatroom
) => {
  const idsArr = reduce(
    webHookData?.data?.chatroom_group,
    (a, v) => {
      if (!includes(a, v.id)) {
        a = [...a, v.id];
      }
      return a;
    },
    []
  );
  return includes(idsArr, currentChatroom?.chatroom_owner_id);
};

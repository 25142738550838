import React, { useRef, useEffect, useState, Fragment } from "react";
import ChatInput from "./ChatInput";
// import API from '@lib/api';
// import useDimensions from "@utils/useDimensions";
import InfiniteScroll from "react-infinite-scroller";
import Utils from "@utils";
import PropTypes from "prop-types";
import { isUndefined, isEmpty } from "lodash";
import "./Chat.css";
import { useDispatch } from "react-redux";
import ChatHeader from "./ChatHeader";
import ChatBlock from "./ChatBlock";
import { chatroomActions } from "@store/chatroom-slice";
import { layoutActions } from "@store/layout-slice";
import MessageService from "@services/common/message/MessageService";
import { messagehistoryActions } from "@store/messagehistory-slice";
function Chat({ cable }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [pagination, setPagination] = useState(null);
  // const [hasMoreData, setHasMoreData] = useState(false);
  // const [messages, setMessages] = useState([]);
  const [isReverse, setIsReverse] = useState(true);
  const [loadAllMessages, setLoadAllMessages] = useState(true);
  let endMessageRef = useRef(null);
  const isCurrentChatroomDeleted = Utils.getIsCurrentChatroomDeleted();
  const isCurrentChatroomParticipantsDeleted =
    Utils.getIsCurrentChatroomParticipantsDeleted();
  const realTimeChartooms = Utils.getRealTimeChatroomMessages();
  const realTimeParticipants = Utils.getRealTimeParticipantsMessages();

  const current_chatroom = Utils.getCurrentChatroom();
  const user = Utils.getCurrentUser();
  const messagesHistory = Utils.getMessagesHistory();

  const scrollToBottom = () => {
    setTimeout(()=>{
      endMessageRef?.current?.scrollIntoView({
        // behavior: "smooth",
        block: "nearest",
      },500);
    })
  };

  useEffect(() => {
    if (
      current_chatroom &&
      endMessageRef?.current &&
      !isEmpty(messagesHistory) &&
      loadAllMessages
    ) {
      scrollToBottom();
      setLoadAllMessages(false);
    }
  }, [messagesHistory, loadAllMessages]);

  //load messages from current chatroom >>>>>>>>>>>>>>>>>>>>>>>>>
  // useEffect(() => {
  //   if (user.session_user_id && current_chatroom?.id) {
  //     // load with new messages
  //     loadMesages(false)
  //   }
  // }, []);

  useEffect(() => {
    // setMessages([]) redux
    if (user.session_user_id && current_chatroom?.id) {
      // fresh load
      loadMesages(
        `/users/${user.session_user_id}/message_history?chatroom_id=${current_chatroom?.id}`,
        "firstFetch"
      );
    }
    return () => {
      setLoading(true);
      setLoadAllMessages(true);
      setPagination([]);
      dispatch(messagehistoryActions.setMessageHistory([]));
    };
  }, [user?.id, current_chatroom?.id]);

  //watch loading
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (isLoading) {
  //       setLoading(false);
  //     }
  //   }, 1000);
  // }, [isLoading]);

  useEffect(() => {
    if (user.session_user_id && current_chatroom) {
      cable.subscriptions.create(
        {
          channel: "RoomChannel",
          room_id: current_chatroom.uuid,
          // user_id: user.session_user_id,
          // name: current_chatroom.title
        },
        {
          received: (message) => {
            // debugger;
            console.log("===message received====", message);
            // setMessages([...messages, message]);
            if (isEmpty(message?.action)) {
              // UPDATE WHEN SAME USER ACTIVED.
              //  const current_chatroom1 = Utils.getCurrentChatroom();
              const current_chatroom_str =
                sessionStorage.getItem("current_chatroom");
              const current_chatroom1 = JSON.parse(current_chatroom_str);
              if (current_chatroom1.id === message.chatroom_id) {
                scrollToBottom();
                dispatch(messagehistoryActions.updateMessageHistory(message));
                //
              }
              // for new [tag] set on left side
              dispatch(chatroomActions.updatedChatroomArrayByRedux(message));
            } else {
              // dispatch(messagehistoryActions.setRealTimeUpdateMessages(message))
              dispatch(
                chatroomActions.setIsCurrentChatroomDeleted({ message, user })
              );
              dispatch(layoutActions.setRightBar(false));
            }

            // const _messages = pushNewMessageInLast(messages, message)
            // setMessages(_messages); redux
          },
        }
      );
    }
  }, [user.session_user_id, cable.subscriptions, current_chatroom]);

  // It's important to add messages in the dependency array, this had been bothering me for days! If messages is not in the dependency array, when component first
  // renders, after user.session_user_id gets value, the line 28 useEffect starts fetch data and setMessages(data), but during this time, message is still with its initial value,
  // which is [], and when you access messages in received(data), it is [], and for reasons beyond my understanding, the data within received stays unchanged unless
  // the useEffect wrapping it gets re-called. So now, when you use messages to setMessages([...messages, data]), the messages will always be [], and setMessages
  // will make messages become [data]. To solve this, messages needs to be added in the dependency array, in doing so, after line 28 useEffect sets messages with
  // data coming from backend, the change of messages will cause the useEffect wrapping received re-run, so now, when messages is accessed within received, it's the
  // correct value. And setMessages within received will change the messages, causing the useEffect wrapping received re-run again, which causes the value of messages
  // within received always be the correct value.

  const loadMesages = (link, fetchTime) => {
    MessageService.getMessageHistory(link)
      .then((r) => {
        if (r?.status === 200) {
          setComponentStates(r, fetchTime);
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const setComponentStates = (response, fetchTime) => {
    if (!isEmpty(response?.data?.data)) {
      if (fetchTime === "firstFetch") {
        dispatch(messagehistoryActions.setMessageHistory(response?.data?.data));
      } else {
        dispatch(
          messagehistoryActions.updateOlddataMessageHistory(
            response?.data?.data
          )
        );
      }
      let hasMoreData =
        response.data.meta.pagination.current_page !==
          response.data.meta.pagination.total_pages &&
        response.data.meta.pagination.total_pages > 1 &&
        response.data.meta.pagination.next_page > 0 &&
        response.data.meta.pagination.previous_page !==
          response.data.meta.pagination.next_page
          ? true
          : false;
      setLoading(JSON.parse(JSON.stringify(hasMoreData)));
      setPagination(response.data.meta.pagination);
    } else {
      setPagination(null);
      setLoading(false);
    }
  };

  const fetchMoreData = () => {
    if (!isUndefined(pagination?.links[1]) && isLoading) {
      loadMesages(pagination?.links[1]?.url, "nextFetch");
    } else {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {(() => {
        if (Boolean(current_chatroom)) {
          return (
            <div
              data-testid="chatComponent"
              className="chat-display flex flex-col h-full w-full"
            >
              <ChatHeader current_chatroom={current_chatroom} />
              <div
                className={`chat-display-body flex flex-col ${
                  (isEmpty(current_chatroom) || isEmpty(messagesHistory)) &&
                  "justify-center"
                }`}
              >
                <InfiniteScroll
                  initialLoad={false}
                  loadMore={fetchMoreData}
                  className="infiniteScroll flex flex-1"
                  hasMore={isLoading}
                  // loader={isEmpty(current_chatroom) ? null : <div className="py-2"><Loading /></div>}
                  useWindow={false}
                  isReverse={isReverse}
                >
                  {!isEmpty(current_chatroom) && !isEmpty(messagesHistory) ? (
                    <div className="message-list flex flex-col flex-1 justify-end olg:my-5 mmd:mx-6.1">
                      <div className="flex-grow flex flex-col space-y-3.5 justify-end overflow-hidden scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
                        {messagesHistory
                          .map((item, index) => {
                            return (
                              <Fragment key={index}>
                                <ChatBlock
                                  messageObj={item}
                                  // isSender={item.sender_id === user.session_user_id}
                                />
                              </Fragment>
                            );
                          })
                          .reverse()}
                        {/* left user in chat group chat */}
                        {/* <div className="text-red flex justify-center font-semibold text-xs">Dianne Russell has left the group chat</div> */}
                      </div>
                      {isReverse && (
                        <div className="endMessageRef" ref={endMessageRef} />
                      )}
                    </div>
                  ) : (
                    <div className="message-list flex flex-col flex-1 items-center justify-center olg:py-5 mmd:px-6.1">
                      <div className="selectAndStartBox flex flex-1">
                        <span>
                          <img src="images/icons/chat-round.svg" alt="Chat" />
                        </span>
                        <div className="font-normal text-lg text-gray80">
                          {isEmpty(current_chatroom)
                            ? "Select or Start New Chat."
                            : "Send message to start chat."}
                        </div>
                      </div>
                      <div className="text-xs font-bold text-black">
                        {current_chatroom?.newCreated}
                      </div>
                    </div>
                  )}
                </InfiniteScroll>
              </div>

              {isCurrentChatroomDeleted ? (
                <Fragment>
                  {realTimeChartooms.map((el) => (
                    <div
                      key={el.id}
                      className="dateHeader overflow-hidden flex items-center justify-center font-semibold mb-3.5  text-xs text-red"
                    >
                      <span>{el?.message}</span>
                    </div>
                  ))}
                </Fragment>
              ) : null}
              {isCurrentChatroomParticipantsDeleted ? (
                <Fragment>
                  {realTimeParticipants.map((el) => (
                    <div
                      key={el.id}
                      className="dateHeader overflow-hidden flex items-center justify-center font-semibold mb-3.5  text-xs text-red"
                    >
                      <span>{el?.message}</span>
                    </div>
                  ))}
                </Fragment>
              ) : null}

              {!isEmpty(current_chatroom) &&
                !current_chatroom?.is_deleted &&
                !isCurrentChatroomDeleted && (
                  <ChatInput
                    chatroom={current_chatroom}
                    user={user}
                    scrollToBottom={scrollToBottom}
                    // endMessageRef={endMessageRef}
                    // messages={messages}
                    // setMessages={setMessages}
                    cable={cable}
                  />
                )}

              {isCurrentChatroomDeleted && (
                <div className="disabledChartroom">
                  This chat is no longer active. To start a new chat click on
                  "New Chat" button.
                </div>
              )}

              {/* no active chat */}
              {/* <div className="absolute w-full left-0 bottom-0 bg-gray65 py-5 text-xs text-center font-semibold text-gray70 flex justify-center">This chat is no longer active. To start a new chat. Click on "New Chat" Button</div> */}
            </div>
          );
        } else if (!Boolean(current_chatroom)) {
          return (
            <p className="w-full h-full w-max-7xl flex flex-center text-3xl text-indigo-400 items-center w-100 text-center flex-auto justify-center py-6">
              No Chat to Show
            </p>
          );
        }
      })()}
    </React.Fragment>
  );
}

Chat.propTypes = {
  cable: PropTypes.object.isRequired,
};

export default Chat;

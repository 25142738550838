import React from "react";

function ImageLoader(props) {
  const { src, alt, defaultImg, className, ...rest } = props;

  const placeholderImage = () => {
    switch (defaultImg) {
      case "blog":
        return `images/icons/customer_icon.png`;
      default:
        return `images/icons/customer_icon.png`;
    }
  };

  const onImageError = (e) => {
    e.target.src = placeholderImage();
  };

  return (
    <>
      <img
        {...rest}
        className={`${className}`}
        src={src || placeholderImage()}
        alt={alt}
        onError={onImageError}
      />
    </>
  );
}

export default ImageLoader;

import React, { useState, useEffect } from 'react'

const useDimensions = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });


  const fontSizeAsPerScreen = [
    { width: 2560, size: 180 },
    { width: 2340, size: 170 },
    { width: 1920, size: 150 },
    { width: 1720, size: 140 },
    { width: 1536, size: 120 },
    { width: 1440, size: 120 },
    { width: 1024, size: 100 },
    { width: 960, size: 40 },
    { width: 320, size: 30 }
  ]

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setTimeout(() => {
        // const extraPadding = fontSizeAsPerScreen.find((el => el.width <= window.innerWidth)).size;
        // const navbarHeight = document.querySelector('#navbar').offsetHeight;
        // const chatHeaderHeight = document.querySelector('#chatheaderroot').offsetHeight;
        // const chatInputHeight = document.querySelector('#chatInput').offsetHeight;

        // const infinityScroll = window.innerHeight - (extraPadding + navbarHeight + chatHeaderHeight + chatInputHeight);

        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
          // infinityScrollheight: infinityScroll,
        });
      }, 100)
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export default useDimensions;


import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatSectionOpen: false,
  rightBarOpen: false,
  rightBarType: '',

  chatByPortal: {},
  newChatPopup: false,

  newChatType: {},

  groupTogglerType: {},
  groupTogglerPopup: false,
  // 1 == direct chat, 2 = team chat
  currentActiveChatTab : 1, 
  chatHistoryHeight : 380, 
  usedNameInGroupArr : [], 
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    //with payload
    setChatSection(state, action) {
      state.chatSectionOpen = action.payload;
    },
    setRightBar(state, action) {
      state.rightBarOpen = action.payload;
    },
    setRightBarType(state, action) {
      state.rightBarType = action.payload;
    },
    setNewChatPopup(state, action) {
      state.newChatPopup = action.payload;
    },
    setNewChatType(state, action) {
      state.newChatType = action.payload;
    },
    setGroupTogglerPopup(state, action) {
      state.groupTogglerPopup = action.payload;
    },
    setGroupTogglerType(state, action) {
      state.groupTogglerType = action.payload;
    },
    setChatByPortal(state, action) {
      state.chatByPortal = action.payload;
    }, 
    setCurrentActiveChatTab(state, action) {
      state.currentActiveChatTab = action.payload;
    }, 
    setUsedNameInGroupArr(state, action) {
      state.usedNameInGroupArr = action.payload;
    }, 
  },
})

//named export
export const layoutActions = layoutSlice.actions;

//default export
export default layoutSlice;
import API from '@lib/api';
const SERVICE_NAME = `/groups`;

let GroupService = {
  createGroupChatroom: (data) => {
    return API.post(`${SERVICE_NAME}/create_chatroom`, data)
      .then((response) => {
        console.log("==createGroupChatroom created====", response)
        return response
      })
      .catch((error) => {
        console.log("=========createGroupChatroom error===", error);
      });
  },
  toggleGroupMembers: (id, data) => {
    return API.put(`${SERVICE_NAME}/${id}/toggle_members`, data)
      .then((response) => {
        console.log("==toggleGroupMembers created====", response)
        return response
      })
      .catch((error) => {
        console.log("=========toggleGroupMembers error===", error);
      });
  },

  getGroupdetails: (id, data) => {
    // return API.get(`${SERVICE_NAME}/${id}/get_details`, data)
    return API.get(`${SERVICE_NAME}/${id}`, data)
      .then((response) => {
        console.log("==getGroupdetails created====", response)
        return response
      })
      .catch((error) => {
        console.log("=========getGroupdetails error===", error);
      });
  },
}


export default GroupService;


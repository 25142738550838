import API from '@lib/api';
import { PortalType } from "..";
const SERVICE_NAME = `${PortalType}/facilities`;

let FacilityService = {
  getFacilities: (pageNo = 1) => {
    return API.get(`${SERVICE_NAME}?page=${pageNo}`)
      .then((response) => {
        console.log("===load getFacilities===", response)
        return response;
      })
      .catch((error) => {
        console.error("=========getFacilities error===", error);
      });
  },
  createFacilityChatroom: (selectedFacility) => {
    return API.post(`${SERVICE_NAME}/${selectedFacility}/create_chatroom`)
      .then((response) => {
        console.log("== Facility Chatroom created====", response)
        return response;
      })
      .catch((error) => {
        console.error("=========createFacilityChatroom error ===", error);
      });
  },
}


export default FacilityService;

import React from 'react'
import PropTypes from 'prop-types';

const LibIconButton = ({ extraclass, icon, text, btntype, iconsize, ...props}) => {

  return (
    <button
      data-testid="button-icon"
      className={`btn inline-flex items-center 
      justify-center transition duration-500 ease-in-out
       text-gray-500 hover:bg-gray-300_ 
      focus:outline-none ${extraclass} bg-${btntype ? btntype : 'default'}`}
      {...props}
      >
      <img src={`/images/icons/${icon}`} alt={text} width={iconsize} height={iconsize} />
    </button>
  )
}

LibIconButton.propTypes = {
  iconsize: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  extraclass: PropTypes.string,
  btntype: PropTypes.string.isRequired,
}

export default LibIconButton


{/* <LibIconButton
  extraclass={'rounded-full h-10 w-10 '}
  icon={'emoji-smile.svg'}
  text={'Smile'}
  
  <LibIconButton
  size={20}
  onClick={() => showDetail()}
/> */}
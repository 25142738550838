import React, { Fragment } from 'react'
// import { chatroomActions } from '@store/chatroom-slice';
import Utils from "@utils";
import LibIconTextButton from "@components/Common/Button/LibIconTextButton";
import { layoutActions } from "@store/layout-slice";
import { useDispatch } from "react-redux";
// import PropTypes from 'prop-types';
// import { errorHandler } from '@utils/errorHandler';
// import viewHelper from '@utils/viewHelper';
// import { toast } from 'react-toastify';
import useDimensions from "@utils/useDimensions";
import { capitalizeHandler } from "@utils";
// import ChatroomService from "@services/common/chatroom/ChatroomService";
// import ConfirmBox from '@components/Common/Dialogue/ConfirmBox';
import HOCdeleteChatroom from '../ExtraComponent/HOCdeleteChatroom';
import { defaultImageLoad } from '../../utils';

const GroupHeaderMobile = ({ deleteGroupChatRoom }) => {
  const dispatch = useDispatch();
  const user = Utils.getCurrentUser();
  const chatroomDetail = Utils.getCurrentChatroomDetail();

  const setSidebarClose = () => {
    dispatch(layoutActions.setNewChatPopup(false))
    dispatch(layoutActions.setRightBar(false))
    dispatch(layoutActions.setGroupTogglerType({}));
    dispatch(layoutActions.setGroupTogglerPopup(false))
  }

  return (
    <Fragment>
      <span className="darkbackforMobile bg-secondary lg:hidden flex absolute inset-0 bottom-auto z-0"></span>
      <div className='flex flex-col w-full relative'>
        <div className="flex items-center justify-between lg:justify-end ">
          <LibIconTextButton
            extraclass={'lg:hidden text-white'}
            icon={'back-arrow-white.svg'}
            text={'Back'}
            btntype={'plain'}
            labeltext={'Back'}
            labelClass='!text-lg'
            onClick={setSidebarClose}
            iconsize={15}
          />
          {/* <LibIconTextButton
            extraclass={'text-white lg:text-black'}
            icon={(dimentions?.width > 1024) ? 'bin-black.svg' : 'bin-white.svg'}
            text={'Delete Group'}
            btntype={'plain'}
            labeltext={'Delete Group'}
            labelClass='!font-semibold'
            iconsize={21}
            onClick={deleteGroupChatRoom}
          /> */}
        </div>
        <div className="flex-1 teamLogoMobile team-lists-container h-full flex flex-col overflow-y-auto pt-16 lg:pt-0">
          <div className="">
            <div className="clubLogo">
              {/* Not getting image use this code */}
              <div className="bg-secondary rounded-full static-info-icon flex justify-center items-center">
                <img data-testid="currentRecieverImage"
                  className="w-11 h-11"
                  aria-hidden
                  src={`/images/icons/${defaultImageLoad(chatroomDetail, user)}`}
                  alt="header-logo" />
              </div>
              {/* getting image use this code */}
              {/* <img className="image rounded-full server-image" src="/images/epic_games.png" alt="club name" /> */}
            </div>
            <div className="clubName text-xl font-medium text-center">
              {capitalizeHandler(chatroomDetail?.name)}
            </div>
          </div>
          <div className="flex-1 overflow-y-auto mb-6.5 no-of-members">
            <div className="clubAddress text-xs font-medium text-center text-b400">{chatroomDetail?.member_ids?.length} Members</div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default HOCdeleteChatroom(GroupHeaderMobile)

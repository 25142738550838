export const themeProvider = (type) => {
    switch (type) {
        case 'customer':
            return {
                "--theme-primary": "#F8991F",
                "--theme-secondary": "#232E3E",
                "--theme-chatroomFirst": "#E1E1E1",
                "--theme-chatroomSecond": "#4353FF",
                "--theme-light-blue": "#DEE8FF",
                "--theme-green": "#0C611A",
                "--theme-mainbgColor": "#FFFFFF",
                "--theme-secondbgColor": "#F5F6F8",
                "--theme-mainInputBg": "#CBCBCB",
                "--theme-white": "#FFFFFF",
                "--theme-chatInput": '#FFFFFF',
            }
        case 'user':
            return {
                "--theme-primary": "#007AFF",
                "--theme-secondary": "#232E3E",
                "--theme-chatroomFirst": "#F2F2F2",
                "--theme-chatroomSecond": "#007AFF",
                "--theme-light-blue": "#DEE8FF",
                "--theme-green": "#0C611A",
                "--theme-mainbgColor": "#EBF1FF",
                "--theme-secondbgColor": "#FFFFFF",
                "--theme-mainInputBg": "#CBCBCB",
                "--theme-white": "#FFFFFF",
                "--theme-chatInput": '#F2F2F2',
            }
        case 'club_user':
            return {
                "--theme-primary": "#28BFBD",
                "--theme-secondary": "#232E3E",
                "--theme-chatroomFirst": "#F2F2F2",
                "--theme-chatroomSecond": "#232E3E",
                "--theme-light-blue": "#DEE8FF",
                "--theme-green": "#0C611A",
                "--theme-mainbgColor": "#EAEDF2",
                "--theme-secondbgColor": "#FFFFFF",
                "--theme-mainInputBg": "#CBCBCB",
                "--theme-white": "#FFFFFF",
                "--theme-chatInput": '#F2F2F2',
            }
        default:
            return {
                "--theme-primary": "#F8991F",
                "--theme-secondary": "#232E3E",
                "--theme-chatroomFirst": "#E1E1E1",
                "--theme-chatroomSecond": "#4353FF",
                "--theme-light-blue": "#DEE8FF",
                "--theme-green": "#0C611A",
                "--theme-mainbgColor": "#FFFFFF",
                "--theme-secondbgColor": "#F5F6F8",
                "--theme-mainInputBg": "#CBCBCB",
                "--theme-white": "#FFFFFF",
                "--theme-chatInput": '#FFFFFF',
            }
    }
}

export function applyTheme(type = 'customer') {
    const root = document.documentElement;
    const themeColor = themeProvider(type);
    Object.keys(themeColor).forEach((cssVar) => {
        root.style.setProperty(cssVar, themeColor[cssVar]);
    });
}

import API from '@lib/api';
import { PortalType } from "..";
const SERVICE_NAME = `${PortalType}/clubs`;


let ClubService = {
  getClubs: (user_id, pageNo = 1) => {
    return API.get(`${SERVICE_NAME}?user_id=${user_id}&page=${pageNo}`)
      .then((response) => {
        console.log("===load clubs===", response)
        return response;
      })
      .catch((error) => {
        console.error("=========getClubs error===", error);
      });
  },
  createClubChatroom: (selectedClub) => {
    return API.post(`${SERVICE_NAME}/${selectedClub}/create_chatroom`)
      .then((response) => {
        console.log("==Club chatroom created====", response)
        return response;
      })
      .catch((error) => {
        console.error("=========createClubChatroom error ===", error);
      });
  },
}


export default ClubService;

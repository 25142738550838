import React, { Children } from 'react'
import PropTypes from 'prop-types';

const LibTextButton = ({ children, extraclass, btntype, ...props }) => {
  return (
    <button
      data-testid="button-text"
      className={`lib_btn h-37 py-2 rounded text-black text-base ${extraclass ? extraclass : 'btn-primary'}`}
      type={btntype ? btntype : 'button'}
      // onClick={clickEvent}
      {...props}
    >
      {children}
    </button>
  )
}

LibTextButton.propTypes = {
  children: PropTypes.string.isRequired,
  extraclass: PropTypes.string.isRequired,
  btntype: PropTypes.string,
  clickEvent: PropTypes.func
}

export default LibTextButton



// <LibTextButton
// extraclass={'bg-none'}
// onClick={() => setIsOpen(false)}>
// Close
// </LibTextButton>
import React, { useEffect, useRef, useState } from "react";
import Picker from 'emoji-picker-react';
import LibIconButton from "@components/Common/Button/LibIconButton";
import LibInput from "@components/Common/Input/LibInput";
import PropTypes from 'prop-types';
import MessageService from "@services/common/message/MessageService";
import Utils from "@utils";
import { isEmpty } from "lodash";
import { chatroomActions } from '@store/chatroom-slice';
import { useDispatch } from "react-redux";
import TooLongMessage from "@components/Common/Dialogue/TooLongMessage";
// import { pushNewMessageInLast } from "../../utils";

function ChatInput({ chatroom, user, scrollToBottom }) {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const [chatInput, setChatInput] = useState("");
  const [tooLong, setToolong] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [openEmoji, setOpenEmoji] = useState(false);

  const isCurrentChatroomParticipantsDeleted = Utils.getIsCurrentChatroomParticipantsDeleted();

  const onEmojiClick = (emojiObject) => {
    setChosenEmoji(emojiObject);
  };

  useEffect(() => {
    if (chosenEmoji?.emoji) {
      setChatInput(chatInput + (chatInput ? ' ' : '') + chosenEmoji?.emoji)
    }
  }, [chosenEmoji])

  useEffect(() => {
    setChatInput("");
  }, [chatroom])

  function handleSubmit(e) {
    let isSubmit = true;

    e.preventDefault();

    if (chatInput.length > 2000) {
      setToolong(true)
    } else {

      const payload = {
        room_id: chatroom.id,
        body: chatInput,
      };

      if (chatInput !== "" && isSubmit) {
        setChatInput("");
        MessageService.createUserMessage(user.session_user_id, payload).then((r) => {
          if (r.status === 201) {
            isSubmit = false;
            scrollToBottom();
            setOpenEmoji(false)
            if (isCurrentChatroomParticipantsDeleted) {
              dispatch(chatroomActions.setFalseIsCurrentChatroomParticipantsDeleted())
            }
            // COMMENT STOP 2 TIME CALL setMessages 
            // setMessages([...messages, r.data]);
            // const _messages = pushNewMessageInLast(messages, r.data)
            // setMessages(_messages);
          }
        });

        // API.post(`/users/${user.session_user_id}/create_message`, payload).then((r) => {
        //   console.log("===received===", r);
        //   if (r.status === 201) {
        //     setChatInput("");
        //     setMessages([...messages, r.data]);
        //   }
        // });
      }
    }

  }


  const emojiClickHandler = () => {
    setOpenEmoji(!openEmoji)
  }

  const startTypingHandler = () => {
    setOpenEmoji(false)
    inputRef.current.focus()
  }

  return (
    <div data-testid="chatInputComp" id="chatInput" className="chat-input mt-4 lg:rounded olg:border mmd:border-t border-b200 !bg-chatInput">
      <div className="chatInputInner !bg-chatInput">
        <form onSubmit={handleSubmit} className="relative flex w-100 ">
          <LibInput
            disabled={isEmpty(chatroom)}
            value={chatInput}
            extraclass='bg-chatInput border-0 text-base osm:text-lg !px-5 text-b20 mmd:placeholder-b20 font-medium'
            placeholder="Enter Message!"
            onChange={e => setChatInput(e.target.value)}
            inputref={inputRef}
          />

          <div className="items-center flex">
            {openEmoji ?
              <div className="emojiPicker">
                <Picker onEmojiClick={onEmojiClick}
                  emojiStyle={"google"}
                  defaultCaption={false}
                  categories={["smileys_people"]}
                  disableAutoFocus={false} native />
                {/* {chosenEmoji && <EmojiData chosenEmoji={chosenEmoji} />} */}
              </div>
              : null}

            <LibIconButton
              extraclass={'rounded-lg emoji-icon'}
              icon={'emoji-smile.svg'}
              disabled={isEmpty(chatroom)}
              text={'Smile'}
              type="button"
              btntype='default'
              iconsize={28}
              onClick={emojiClickHandler}
            />

            <LibIconButton
              extraclass={'rounded-full send-icon-bg ml-6 mr-5 oml:w-7.5 oml:h-7.5'}
              icon={'send.svg'}
              disabled={isEmpty(chatroom)}
              text={'send'}
              behaviour={'submit'}
              btntype={'primary'}
              iconsize={19}
            />

          </div>
        </form>
      </div>

      {openEmoji ?
        <div className="emojiPickerBackDrop" onClick={startTypingHandler} />
        : null}

      <TooLongMessage
        isOpen={tooLong}
        setIsOpen={setToolong}
      />
    </div>
  );
}


ChatInput.propTypes = {
  chatroom: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  scrollToBottom: PropTypes.func
  // messages: PropTypes.array.isRequired,
  // setMessages: PropTypes.func.isRequired,
}

export default ChatInput;


// const EmojiData = ({ chosenEmoji }) => (
//   <div>
//     <strong>Unified:</strong> {chosenEmoji.unified}
//     <br />
//     <strong>Names:</strong> {chosenEmoji.names.join(', ')}
//     <br />
//     <strong>Symbol:</strong> {chosenEmoji.emoji}
//     <br />
//     <strong>ActiveSkinTone:</strong> {chosenEmoji.activeSkinTone}
//   </div>
// );
import API from '@lib/api';
const SERVICE_NAME = "/sessions";

let SessionService = {
  postSession: (data) => {
    return API.post(`${SERVICE_NAME}`, data)
      .then((response) => {
        console.log("===create session===", response)
        return response;
      })
      .catch((error) => {
        console.error("=========postSession error===", error);
      });
  }
}



export default SessionService;

/* eslint-disable array-callback-return */
import React, { Suspense, lazy, useEffect, useState, Fragment } from "react";
//set chatroom data
import Utils from "@utils";
import LibInput from "@components/Common/Input/LibInput";
import Loading from "@components/Common/Loading";
// import LibIconTextButton from "@components/Common/Button/LibIconTextButton";
import { layoutActions } from '@store/layout-slice';
import { filterTableRowHandler } from '@utils';
import LibIconButton from '@components/Common/Button/LibIconButton';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import SelectBox from '@components/Common/SelectBox/Index';
// import PropTypes from 'prop-types';
import { filter, includes, isEmpty, map, orderBy } from "lodash";
// import { capitalizeHandler } from "@utils";
import useDimensions from "@utils/useDimensions";
// import ChatroomService from "@services/common/chatroom/ChatroomService";
// import { chatroomActions } from '@store/chatroom-slice';
// import { errorHandler } from '@utils/errorHandler';
// import viewHelper from '@utils/viewHelper';
// import { toast } from 'react-toastify';
import GroupHeaderMobile from "@components/NewChatGroup/GroupHeaderMobile";
import HOCdeleteChatroom from "../../ExtraComponent/HOCdeleteChatroom";

const ListItem = lazy(() => import("@components/Information/GroupInfo/ListItem"));

const clubOptions = [
  { id: "1", value: 'add_member', name: 'Add New Members' },
  { id: "2", value: 'remove_member', name: 'Remove Members' },
  { id: "3", value: 'delete_group', name: 'Delete Chat Group' },
]

function GroupInfo({ deleteGroupChatRoom }) {

  const dimentions = useDimensions();
  const dispatch = useDispatch()
  const [filterText, setFilterText] = useState('')
  const [usedTeamMembers, setUsedTeamMembers] = useState([])
  const { groupTogglerType } = useSelector((state) => ({ ...state.layout, shallowEqual }));

  const chatroomDetail = Utils.getCurrentChatroomDetail();
  const isChatroomLoading = Utils.getCurrentChatroomLoading();
  const currentChatroom = Utils.getCurrentChatroom();
  const isChatroomCreatedByUser = Utils.getIsChatroomCreatedByUser();
  const user = Utils.getCurrentUser();

  // filter team members
  const filterTeamMembersHandler = (value) => {
    setFilterText(value)
  }

  const showDetail = () => {
    dispatch(layoutActions.setRightBar(false))
  }

  const chatByHandler = (value) => {
    switch (value.value) {
      case 'add_member':
      case 'remove_member':
        // add_new and delete_member
        dispatch(layoutActions.setGroupTogglerPopup(true))
        dispatch(layoutActions.setGroupTogglerType(value));

        // new_chatPopup
        dispatch(layoutActions.setNewChatPopup(false));
        dispatch(layoutActions.setChatByPortal({}));
        break;
      case 'delete_group':
        deleteGroupChatRoom()
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    if (!isEmpty(chatroomDetail?.team?.team_members)) {
      const _members = filter(chatroomDetail?.team?.team_members, (el) => includes(chatroomDetail?.member_ids, el.id))
      const team_members = map(_members, (item) => {
        if (user.session_user_id === item.id) {
          item = { ...item, full_name: item.full_name + " (You) ", is_owner: true, }
        }

        item = { ...item, checked: true }
        return item
      }
      );
      const _team_members = orderBy(team_members, ['is_owner'], ['asc']);
      setUsedTeamMembers(_team_members)
    }

  }, [chatroomDetail?.team?.team_members])

  // const setSidebarClose = () => {
  //   dispatch(layoutActions.setRightBar(false))
  //   dispatch(layoutActions.setNewChatPopup(false))
  //   dispatch(layoutActions.setGroupTogglerType({}));
  //   dispatch(layoutActions.setGroupTogglerPopup(false))
  // }

  const headingHandler = () => {
    return (<h3 className="text-lg font-medium flex justify-between items-center gap-3">
      <span className='labelH3 text-2xl lg:text-lg font-medium'>Members</span>
      {isChatroomCreatedByUser ?
        <span className='selectBox'>
          <SelectBox
            className="w-48 groupSelectBox osm:w-fit"
            displayAs="button"
            optionClassName="font-semibold text-sm"
            listClass='!w-fit osm:right-0 new-chat-menu'
            options={clubOptions}
            onChange={chatByHandler}
            value={groupTogglerType.name}
            displayName={groupTogglerType.name ? groupTogglerType.name : "Manage"}
          />
        </span>
        : null
      }
    </h3>)
  }

  return (
    <div className="flex w-full flex-col h-full chat-lists overflow-hidden">
      {(() => {
        if (dimentions?.width > 1024) {
          return (
            <div className="listTeamsHeader w-full mb-4 hidden_ lg_: flex">
              <div className="flex w-full items-center justify-between">

                {headingHandler()}

                <LibIconButton
                  extraclass={'bg-lb iconButton-35 rounded'}
                  icon={'close.svg'}
                  text={'close'}
                  btntype='default'
                  onClick={() => showDetail()}
                  iconsize={20}
                />
              </div>
            </div>
          )
        } else {
          return (
            <GroupHeaderMobile />
          )
        }
      })()}
      {(() => {
        if (isChatroomLoading) {
          return <Loading />
        } else {
          return (
            <div className="flex flex-col h-full overflow-hidden">

              <div className="flex w-full items-center mb-2.5 lg:hidden justify-between">
                {headingHandler()}
              </div>

              <div className="listTeamsSearch">
                <div className="mb-3.6">
                  <LibInput
                    placeholder="Search members"
                    extraclass='border-gray20 inputBorderSM text-sm'
                    onChange={e => filterTeamMembersHandler(e.target.value)}
                    // changeevent={filterTeamMembersHandler}
                    value={filterText}
                  />
                </div>
              </div>
              <div className="chat-lists flex-1 h-full flex flex-col overflow-hidden">
                {/* <h3 className="font-semibold pb-3 text-normal">Team Members</h3> */}
                <div className="flex-1 team-lists-container overflow-y-auto">
                  <div className="space-y-2.5">
                    {usedTeamMembers?.map((item, index) => (
                      <Fragment key={index}>
                        {filterTableRowHandler(filterText, item) ?
                          <ListItem item={item} key={item.id} />
                          : null
                        }
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )
        }
      })()}
    </div>
  );
}

GroupInfo.propTypes = {
  // deleteGroupChatRoom: PropTypes.func.isRequired
}

export default HOCdeleteChatroom(GroupInfo);

import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import PropTypes from 'prop-types';
import Chat from "@components/Chat/Chat";
import BackgroundLayer from "@components/Common/Dialogue/Transitions/BackgroundLayer";
import { layoutActions } from "@store/layout-slice";

const ChatSectionMobile = (props) => {
  const dispatch = useDispatch()
  const { cable } = props;
  const { chatSectionOpen } = useSelector((state) => ({ ...state.layout, shallowEqual }))

  const hideChatSection = () => {
    dispatch(layoutActions.setChatSection(false))
  }

  return (
    <Transition.Root id="chatSectionMobile" show={chatSectionOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        onClose={hideChatSection}>
        <BackgroundLayer />
        <div className="fixed inset-0 z-30 flex ">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-out-in duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative flex w-full flex-1 flex-col bg-white lg:bg-secondbgColor focus:outline-none">
              <div className="flex flex-shrink-0 border-t border-gray-200 p-0 flex-1 overflow-hidden">
                <Chat cable={cable} />
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

ChatSectionMobile.propTypes = {
  cable: PropTypes.object.isRequired
}

export default ChatSectionMobile

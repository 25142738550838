import React, { memo, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import PropTypes from 'prop-types';

import Home from "@components/Home";
import Loading from "@components/Common/Loading";


function AppRoutes({ cable }) {
  return (
    <Suspense fallback={<div />}>
      <Routes>
        <Route path="/my-chats" element={<Home cable={cable} />} />
      </Routes>
    </Suspense>
  );
}

AppRoutes.propTypes = {
  cable: PropTypes.object.isRequired
}

export default memo(AppRoutes);

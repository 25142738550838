// utils/viewHelper.js

import React from 'react';

function alertClass(type) {
    let classes = {
        error: 'error',
        alert: 'warning',
        warning: 'warning',
        info: 'info',
        success: 'success'
    };
    return classes[type] || classes.success;
}

function alertIcons(type) {
    switch (type) {
        case 'error':
            return <i className="fa fa-exclamation-triangle" style={{ fontSize: '1.2rem' }} />;
        case 'success':
            return <i className="fa fa-check-circle" style={{ fontSize: '1.2rem' }} />;
        case 'info':
            return <i className="fa fa-info-circle" style={{ fontSize: '1.2rem' }} />;
        case 'warning':
            return <i className="fa fa-exclamation-triangle" style={{ fontSize: '1.2rem' }} />;
        default:
            return <i className="fa fa-info-circle" style={{ fontSize: '1.2rem' }} />;
    }
}

export function returnToastMarkup(data) {
    // console.log(data);
    function createMarkup(data) {
        return { __html: data?.message };
    }

    return (
        <div className={`notify notify-top-center rounded_ ${alertClass(data?.type)}`} style={{ top: 50 }}>
            {/* <div className="notify-icon">
                <div className="notify-icon-inner" style={{ marginTop: -9 }}>
                    {alertIcons(data?.type)}
                </div>
            </div> */}
            <div className="notify-text">
                <h3 className='text-sm font-semibold text-inherit text-gray-800_'>{data?.title}</h3>
                {data?.message ?
                    <div className='mt-2 text-sm text-inherit text-gray-700_'>
                        <p dangerouslySetInnerHTML={createMarkup(data)} />
                    </div>
                    : null
                }
            </div>
        </div>
    );
}

export default { returnToastMarkup };
import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import useDimensions from "@utils/useDimensions";
import PropTypes from 'prop-types';
import Utils from "@utils";
import RightSection from "@components/Layout/RightSection";
import BackgroundLayer from "@components/Common/Dialogue/Transitions/BackgroundLayer";
import { layoutActions } from "@store/layout-slice";
import { includes } from "lodash";

const SidebarRight = (props) => {
  const dispatch = useDispatch()
  const dimentions = useDimensions();
  const { cable, isDisplay } = props;
  const { rightBarOpen } = useSelector((state) => ({ ...state.layout, shallowEqual }))
  const currentChatroom = Utils.getCurrentChatroom();

  const setSidebarOpen = () => {
    dispatch(layoutActions.setRightBar(false))
  }

  return (

    <Transition.Root id="sidebarLaptop" show={rightBarOpen && isDisplay} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        onClose={setSidebarOpen}
      >
        <BackgroundLayer />

        <div className="sidebarRightInner fixed right-0 left-auto inset-0 z-30 flex w-full oml:w-6/12 omd:w-6/12">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <Dialog.Panel className="relative flex lg:w-[400px] max-w-none w-full flex-1 flex-col bg-white lg:bg-secondbgColor  focus:outline-none">

              {(dimentions?.width <= 1024) && (includes(['User', 'Customer', 'ClubUser', 'ClubDetail', 'Company', 'ClubCompany', 'ClubCompany_', 'FacilityDetail', 'ClubContact'], currentChatroom?.chatroom_owner)) ?
                <span className={`darkbackforMobile bg-secondary lg:hidden flex absolute inset-0 bottom-auto z-0`}></span>
                :
                null
              }

              <div className="relative flex flex-1 w-full flex-shrink-0 p-5 lg:px-[2.143rem] overflow-hidden">
                <div className="flex-1 overflow-hidden z-10">
                  <RightSection cable={cable} />
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

SidebarRight.propTypes = {
  cable: PropTypes.object.isRequired
}

export default SidebarRight

/* eslint-disable array-callback-return */
import React, { Suspense, lazy, useEffect, useState, Fragment } from "react";
import LibIconTextButton from "@components/Common/Button/LibIconTextButton";
import Utils from "@utils";
import LibInput from "@components/Common/Input/LibInput";
import Loading from "@components/Common/Loading";
import { layoutActions } from '@store/layout-slice';
import PropTypes from 'prop-types';
import { filterTableRowHandler, TeamServiceFromType } from '@utils';
import LibIconButton from '@components/Common/Button/LibIconButton';
import { useDispatch } from "react-redux";
import { isArray } from "lodash";

const TeamList = lazy(() => import("@components/Information/Teams/TeamList"));

function TeamsList({ deleteGroupChatRoom }) {

  const dispatch = useDispatch()
  const [filterText, setFilterText] = useState('')
  const [listData, setListData] = useState([])

  const chatroomDetail = Utils.getCurrentChatroomDetail();
  const isChatroomLoading = Utils.getCurrentChatroomLoading();
  const isChatroomCreatedByUser = Utils.getIsChatroomCreatedByUser();

  // filter team members
  const filterTeamMembersHandler = (value) => {
    setFilterText(value)
  }

  const showDetail = () => {
    dispatch(layoutActions.setRightBar(false))
  }

  const setSidebarOpen = () => {
    dispatch(layoutActions.setRightBar(false))
  }

  useEffect(() => {
    if (isArray(chatroomDetail?.participants)) {
      setListData(chatroomDetail?.participants)
    }
  }, [chatroomDetail])

  return (
    <div className="flex w-full flex-col h-full chat-lists overflow-hidden">
      <div className="listTeamsHeader w-full mb-4 hidden_ lg_: flex">
        <div className="flex w-full items-center justify-between">
          <h3 className="text-lg font-medium">
            Team Members
          </h3>
          <LibIconButton
            extraclass={'bg-lb iconButton-35 rounded'}
            icon={'close.svg'}
            text={'close'}
            btntype='default'
            onClick={() => showDetail()}
            iconsize={20}
          />
        </div>
      </div>

      {(() => {
        if (isChatroomLoading) {
          return <Loading />
        } else {
          return (
            <div className="flex flex-col h-full overflow-hidden">
              <div className="listTeamsSearch">
                <div className="mb-6">
                  <LibInput
                    placeholder="Search members"
                    extraclass='inputBorderSM text-sm mmd:h-9'
                    onChange={e => filterTeamMembersHandler(e.target.value)}
                    value={filterText}
                  />
                </div>
              </div>
              <div className="chat-lists flex-1 h-full flex flex-col overflow-hidden">
                {/* <h3 className="font-semibold pb-3 text-normal">Team Members</h3> */}
                <div className="flex-1 team-lists-container overflow-y-auto">
                  <div className="space-y-2.5">
                    {listData.map((customer, index) => (
                      <Fragment key={index}>
                        {filterTableRowHandler(filterText, customer) ?
                          <TeamList customer={customer} key={customer.id} />
                          : null
                        }
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
              <div className="listTeamsFooter facilityInfo">
                <div className="flex items-center justify-between lg:justify-end mt-2">
                  <LibIconTextButton
                    extraclass={'lg:hidden text-white'}
                    icon={'back-arrow-white.svg'}
                    text={'Back'}
                    btntype={'plain'}
                    labeltext={'Back'}
                    labelClass='!text-lg'
                    onClick={setSidebarOpen}
                    iconsize={15}
                  />
                  {isChatroomCreatedByUser && <LibIconTextButton
                    extraclass={'text-b75 !gap-1'}
                    icon={'bin-black.svg'}
                    text={'Delete Chat'}
                    btntype={'plain'}
                    labeltext={'Delete chat'}
                    labelClass='!font-semibold'
                    iconsize={21}
                    disabled={!isChatroomCreatedByUser}
                    onClick={deleteGroupChatRoom}
                  />}
                </div>
              </div>
            </div>
          )
        }
      })()}
    </div>
  );
}

TeamsList.propTypes = {
  // cable: PropTypes.object.isRequired
}

export default TeamsList;

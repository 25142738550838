import React, { useEffect, useState } from 'react'
import LibIconButton from '@components/Common/Button/LibIconButton';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Utils from "@utils";
import { layoutActions } from "@store/layout-slice";
import { chatroomActions } from "@store/chatroom-slice";
import ChatroomService from '@services/common/chatroom/ChatroomService';
import GroupService from '@services/common/group/GroupService';
import { isEmpty } from 'lodash';
import { defaultImageLoad } from '@utils';

const ChatHeader = (props) => {
  const { current_chatroom } = props;
  const dispatch = useDispatch();
  const user = Utils.getCurrentUser();
  const [membersCount, setMemberCount] = useState(null)
  const isCurrentChatroomDeleted = Utils.getIsCurrentChatroomDeleted();

  const setListbarOpen = () => {
    dispatch(layoutActions.setRightBar(true))
    getDetailOfCurrentChatRooom()
    //club || facility || team
    // dispatch(layoutActions.setRightBarType('team'))
  }
  const getDetailOfCurrentChatRooom = () => {
    dispatch(chatroomActions.setChatRoomLoading(true));
    if (current_chatroom?.chatroom_owner === "Group") {
      GroupService?.getGroupdetails(current_chatroom?.chatroom_owner_id)
        .then((r) => {
          if (r?.status === 200) {
            dispatch(chatroomActions.setCurrentChatroomDetail(r.data.data));
            dispatch(chatroomActions.setChatRoomLoading(false));
          }
        });
    } else {
      ChatroomService.getChatroomDetails(current_chatroom?.uuid)
        .then((r) => {
          if (r?.status === 200) {
            dispatch(chatroomActions.setCurrentChatroomDetail(r.data.data));
            dispatch(chatroomActions.setChatRoomLoading(false));
          }
        });
    }
  }

  const hideChatSection = () => {
    dispatch(layoutActions.setChatSection(false))
    // dispatch(chatroomActions.setCurrentChatroom(null));
  }

  useEffect(() => {
    if (current_chatroom?.chatroom_owner === 'Group' || current_chatroom?.chatroom_owner === 'Team') {
      setMemberCount(`${current_chatroom?.participants_count} MEMBERS`)
    } else {
      setMemberCount(null)
    }
  }, [current_chatroom])

  const viewDetailBtn = () => {
    if (isCurrentChatroomDeleted) {
      return <div></div>
    } else {
      return <div className='leading-6.5 mmd:leading-4 text-gray55 text-sm font-medium cursor-pointer whitespace-nowrap' onClick={setListbarOpen}>VIEW DETAILS</div>
    }
  }

  return (
    <div data-testid="chatheaderroot" id='chatheaderroot' className={`flex items-center py-5 px-6.1 mb-4 lg:pb-4 lg:p-0 ${current_chatroom.id && 'border-b border-b-gray73'}`}>
      <div className='flex-1 items-center flex overflow-hidden'>
        <div className='buttonSection mmd:mr-2.5 lg:p-1 flex lg:hidden'>
          <LibIconButton
            icon={'back-arrow-black.svg'}
            text={'Back'}
            btntype='e3lg'
            onClick={hideChatSection}
            iconsize={20}
          />
        </div>
        {current_chatroom.id &&
          <div className="chat-display-header overflow-hidden flex-1">
            <div
              data-testid="currentReciever"
              className="flex items-center space-x-3.5 currentChatReceiver">
              {/* Not getting use this code */}
              {current_chatroom?.chatroom_profile_picture ? <img data-testid="currentRecieverImage" className="rounded-full olg:w-14 olg:h-14 w-[50px] h-[50px]" src="/images/epic_games.png" alt="" /> :
                <div className='w-14 h-14 bg-secondary rounded-full flex justify-center items-center'>
                  <img data-testid="currentRecieverImage"
                    className="w-6 h-6"
                    aria-hidden
                    src={`/images/icons/${defaultImageLoad(current_chatroom, user)}`}
                    alt={`image-${current_chatroom?.chatroom_owner}`} />
                </div>}
              <div className="overflow-hidden w-fit flex-1">
                <div data-testid="currentRecieverTitle" className="overflow-hidden whitespace-normal flex flex-1">
                  <span className='text-xxl font-medium capitalize text-ellipsis overflow-hidden text-b900 leading-6 mmd:whitespace-pre mmd:block mmd:truncate mmd:leading-7'>{current_chatroom.title}</span>
                </div>
                {membersCount ?
                  <div className='flex items-center'>
                    <div data-testid="currentRecieversCount" className="text-sm font-semibold mr-2 text-b900 leading-6.5 whitespace-nowrap block truncate">
                      {membersCount}
                    </div>
                    {viewDetailBtn()}
                  </div>
                  : <>{viewDetailBtn()}</>
                }
              </div>
            </div>
          </div>}
      </div>
      <div className='chatHeaderAction flex flex-col gap-2'>
        {/* <LibIconButton
          extraclass={'bg-lb iconButton-35'}
          icon={'close.svg'}
          text={'close'}
          btntype='default'
          // onClick={() => setSidebarOpen()}
          iconsize={16}
        />
        <LibIconButton
          extraclass={'bg-lb iconButton-35'}
          icon={'close.svg'}
          text={'close'}
          btntype='default'
          // onClick={() => setSidebarOpen()}
          iconsize={16}
        /> */}
      </div>
    </div>
  )
}

ChatHeader.propTypes = {
  current_chatroom: PropTypes.object.isRequired
}

export default ChatHeader
import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./user-slice";
import chatroomSlice from "./chatroom-slice";
import layoutSlice from "./layout-slice";
import messagehistorySlice from "./messagehistory-slice";
import teamsSlice from "./teams-slice";

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    chatroom: chatroomSlice.reducer,
    layout: layoutSlice.reducer,
    messages: messagehistorySlice.reducer,
    teams: teamsSlice.reducer,
  }
})

export default store;
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import BackgroundLayer from '@components/Common/Dialogue/Transitions/BackgroundLayer';
import SmallSlideOverLayyer from '@components/Common/Dialogue/Transitions/SmallSlideOverLayyer';
import PropTypes from 'prop-types';
import LibIconButton from '@components/Common/Button/LibIconButton';
import LibButton from '@components/Common/Button/LibButton';
import LibTextButton from '@components/Common/Button/LibTextButton';
// import Utils from "@utils";

const ConfirmBox = ({ isOpen, setIsOpen, deleteGroupChatRoomHandler }) => {

  // const [chatRoomType, setChatRoomType] = useState('')

  // const current_chatroom = Utils.getCurrentChatroom();

  const cancleHandler = () => {
    setIsOpen(false)
  }
  const approveHandler = () => {
    setIsOpen(false);
    deleteGroupChatRoomHandler()
    // setIsTrue(true)
  }

  // useEffect(() => {
  //   let str = ''
  //   const _type = current_chatroom?.chatroom_owner
  //   switch (_type) {
  //     case 'FacilityDetail':
  //     case 'Company':
  //     case 'ClubCompany':
  //       str = 'Facility'
  //       break;
  //     case 'ClubUser':
  //     case 'ClubDetail':
  //     case 'ClubCompany':
  //       str = 'Club'
  //       break;
  //     default:
  //       str = _type
  //       break;
  //   }
  //   setChatRoomType(str)
  // }, [current_chatroom])

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" id="deleteConfirmationBox" className="relative z-50" onClose={setIsOpen}>

        <BackgroundLayer />
        <SmallSlideOverLayyer>
          <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-md bg-white text-left align-middle shadow-xl transition-all">

            {/*header*/}
            <div className="flex items-start justify-between py-5 px-7 rounded-t osm:py-4.5 osm:pb-0">
              <h3 className="text-xxl font-medium flex-1">
                Delete chat?
              </h3>

              <LibIconButton
                extraclass={'rounded h-9 w-9 bg-gray25'}
                icon={'gray-close.svg'}
                text={'Close'}
                btntype='default'
                iconsize={20}
                onClick={cancleHandler}
              />
            </div>
            {/*body*/}
            <div className="relative py-5 px-7 flex-auto osm:py-2.5">
              <p className="text-secondary text-base leading-relaxed">
                Are you sure you want to delete the chat? This will remove all the chat history.
              </p>
            </div>
            {/*footer*/}
            <div className="flex gap-6 items-center justify-end py-3 px-7 mt-9 rounded-b bg-b300 font-semibold">
              <LibTextButton
                extraclass={'bg-none text-sm'}
                onClick={cancleHandler}>
                Close
              </LibTextButton>

              <LibButton
                extraclass={'bg-red text-sm'}
                onClick={approveHandler}>
                Confirm
              </LibButton>

            </div>
          </Dialog.Panel>
        </SmallSlideOverLayyer>
      </Dialog>
    </Transition>
  )
}

ConfirmBox.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
}

export default ConfirmBox;
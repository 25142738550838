import axios from 'axios';
// import { isEmpty, isUndefined } from 'lodash';
// import store from '@store';
// import { showToastify } from '@utils';
// import { errorHandler } from '@utils/errorHandler';
// import { toast } from 'react-toastify';

let baseAPIUrl;
//process.env.NODE_ENV cannot be set, hence setting up a new variable, already present on the server
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    baseAPIUrl = 'https://prod-chat.nattyhatty.com/api/v1/';
} else if (process.env.REACT_APP_ENVIRONMENT === 'sandbox'){
    baseAPIUrl = 'https//dev2-chat.nattyhatty.com/api/v1/';
} else {
    baseAPIUrl = 'https://dev2-chat.nattyhatty.com/api/v1/';
    // baseAPIUrl = 'http://localhost:3003/api/v1/';
}

const search = window.location.search;
const params = new URLSearchParams(search);


// //reset if not the same
// if(localStorage.getItem('current_user_token') !== currentUserToken) {
// 	localStorage.clear();
// 	localStorage.setItem('current_user_token', currentUserToken)
// }

// const get_local_token = () => {
//     // var newState = store.getState();
//     const token = localStorage.getItem('token');
//     if (!isUndefined(token)) {
//         return token;
//     }
// }
// store.subscribe(get_local_token)
//reference - https://pragmaticstudio.com/tutorials/rails-session-cookies-for-api-authentication 
const instance = axios.create({
    baseURL: baseAPIUrl,
    responseType: 'json',
    timeout: 100000,
    credentials: 'same-origin',
    // headers: {
    //     Accept: 'application/json, text/plain, */*',
    //     'X-Requested-With': 'XMLHttpRequest',
    //     Authorization: get_local_token(),
    // }
});

// instance.interceptors.request.use(
//     (config) => {
//         // Do something before request is sent
//         //SET GLOBAL LOADER
//         config.headers['Authorization'] = `Bearer ${get_local_token()}`;
//         return config;
//     },
//     (error) => {
//         console.log(error);
//         // Do something with request error
//         return Promise.reject(function () {
//             // 	Utils.handleAjaxError(error)/
//         });
//     }
// );

// // Add a response interceptor
// instance.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     (error) => {
//         toast.error(errorHandler(error));
//         // Do something with response error
//         console.log(error);
//         if (error?.response?.status === 500) {
//             console.log('xxxxxxxxxx Server call failed xxxxxxxxxxxx');
//         }
//         if (error?.response?.status === 401) {
//             console.log('xxxxxxxxxx unauthorized access xxxxxxxxxxxx');
//         }

//         if (error?.response?.status === 401) {
//             console.log('xxxxxxxxxx user not found xxxxxxxxxxxx');
//         }

//         return Promise.reject(function () {
//             //handle error using toast or redirection
//         });
//     }
// );

export default instance;

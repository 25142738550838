import API from '@lib/api';
import { showToastify } from '@utils';
const SERVICE_NAME = "/chatrooms";

let ChatroomService = {
  getChatRooms: (pageNo) => {
    return API.get(`${SERVICE_NAME}?page=${pageNo}`)
      .then((response) => {
        console.log("===load Chatroom===", response)
        return response;
      })
      .catch((error) => {
        console.error("=========getChatRooms error===", error);
      });
  },

  setPrimaryChatroom: (chatroom_uuid, details) => {
    return API.post(`${SERVICE_NAME}/${chatroom_uuid}/set_primary`, details)
      .then((response) => {
        console.log("===set primary chat room===", response)
        return response;
      })
      .catch((error) => {
        console.error("=========setPrimaryChatroom error===", error);
      });
  },

  createDirectChatRoom: (user_uuid) => {
    return API.post(`${SERVICE_NAME}/create_direct_chatroom`, user_uuid)
      .then((response) => {
        console.log("===load createDirectChatRooms ===", response)
        return response;
      })
      .catch((error) => {
        console.error("=========createDirectChatRooms error===", error);
      });
  },

  getChatroomDetails: (user_uuid) => {
    return API.get(`${SERVICE_NAME}/${user_uuid}/get_chatroom_details`)
      .then((response) => {
        console.log("===load getChatroomDetails ===", response)
        if (response.status === 200) {
          return response
        } else {
          showToastify(response.statusText, 'error')
        }
      })
      .catch((error) => {
        console.error("=========getChatroomDetails error===", error);
      });
  },

  deleteAllTypeChatroom: (crnt_chatroom_uuid, operation) => {

    let URL = `${SERVICE_NAME}/${crnt_chatroom_uuid}?${operation}=true`;

    return API.put(URL)
      .then((response) => {
        console.log("===load deleteAllTypeChatroom ===", response)
        return response;
      })
      .catch((error) => {
        console.error("=========deleteAllTypeChatroom error===", error);
      });
  },

}



export default ChatroomService;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Link } from "react-router-dom";
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

import { capitalizeHandler } from '@utils';

const navigation = [
	{ name: 'Customer', href: '/?uuid=w9pQkOJxhjfseWUVgzT36Q&type=customer', current: true },
	// { name: 'Customer', href: '/?uuid=F6w2gpOvmfQQCSgu_7qprQ&type=customer', current: true },
	{ name: 'Club', href: '/?uuid=pu11p499sTjeedAtSUc06A&type=club_user', current: true },
	{ name: 'Facility', href: '/?uuid=xQWvaNTEyFtwCduTnilg_A&type=user', current: true },
]

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

const Navbar = () => {
	// console.log("Navbar")
	const [currentUser, setCurrentUser] = useState(null)
	const [showNavbar, setShowNavbar] = useState(false)
	// const navigate = useNavigate();
	// const dispatch = useDispatch();
	// const user = Utils.getCurrentUser();

	const { user } = useSelector(state => ({ ...state.user, shallowEqual }));

	useEffect(() => {
		const hostname = window.location.hostname;
		if (hostname === 'localhost') {
			setShowNavbar(true)
		}
		if (user?.user) {
			setCurrentUser(user?.user)
		}
	}, [user])


	const closeWindow = () => {
		window.opener = null;
		window.open("", "_self");
		window.close();
	}
	return (
		<Disclosure as="nav" className="bg-gray-800">
			{({ open }) => (
				<>
					<div className="mx-auto chat_container" id='navbar'>
						<div className="relative flex h-16 px-3 items-center justify-between">
							{/* Mobile menu button*/}
							{/* <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
								<Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
									<span className="sr-only">Open main menu</span>
									{open ? (
										<span>close</span>
									) : (
										<span>open</span>
									)}
								</Disclosure.Button>
							</div> */}
							<div className="flex flex-1 items-center justify-center_ lg:items-stretch lg:justify-start">
								<div className="flex flex-shrink-0 items-center cursor-pointer">
									<img src='/images/icons/Natty-Hatty-logo.png' alt="Natty Hatty" />
								</div>
								<div className="hidden lg:ml-6 lg:block">
									{showNavbar ?
										<div className="flex space-x-4">
											{navigation.map((item) => (
												<Link
													to={item.href}
													key={item.name}
													className={classNames(
														item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
														'px-3 py-2 rounded-md text-sm font-medium'
													)}
													role="button"
												>
													{item.name}
												</Link>
											))}
										</div>
										: null}
								</div>
							</div>
							<div className="absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 lg:pr-0">
								{currentUser ?
									<button
										type="button"
										className="rounded-full hidden lg:flex bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-offset-gray-800"
									>
										<span className="sr-only">View notifications</span>
										<span>{capitalizeHandler(currentUser.first_name)} {capitalizeHandler(currentUser.last_name)} </span>
									</button>
									: null
								}

								{/* Profile dropdown */}
								<Menu as="div" className="relative ml-3">
									<div>
										<Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-800">
											<img
												className="h-9 w-9 rounded-full"
												src={currentUser?.picture_url}
												alt=""
											/>
										</Menu.Button>
									</div>
									<Transition
										as={Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items className="absolute header-profile-menu right-0 z-20 mt-3 w-38 origin-top-right rounded bg-b75 focus:outline-none">
											<Menu.Item>
												{({ active }) => (
													<a
														href="#"
														onClick={closeWindow}
														className={classNames(active ? 'bg-primary rounded-t' : '', 'block px-4 py-3 text-sm text-white font-semibold')}
													>
														Settings
													</a>
												)}
											</Menu.Item>
											<Menu.Item>
												{({ active }) => (
													<a
														href="#"
														onClick={closeWindow}
														className={classNames(active ? 'bg-primary rounded-b' : '', 'block px-4 py-3 text-sm text-white font-semibold')}
													>
														Logout
													</a>
												)}
											</Menu.Item>
										</Menu.Items>
									</Transition>
								</Menu>
							</div>
						</div>
					</div>

					<Disclosure.Panel className="lg:hidden">
						<div className="space-y-1 px-2 pt-2 pb-3">
							{navigation.map((item) => (
								<Disclosure.Button
									key={item.name}
									as="a"
									href={item.href}
									className={classNames(
										item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
										'block px-3 py-2 rounded-md text-base font-medium'
									)}
									aria-current={item.current ? 'page' : undefined}
								>
									{item.name}
								</Disclosure.Button>
							))}
						</div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	)
}

export default React.memo(Navbar)
import API from '@lib/api';
import { PortalType } from '..';
const SERVICE_NAME = `${PortalType}/contacts`;

let ContactsService = {
  getContacts: (pageNo) => {
    return API.get(`${SERVICE_NAME}?page=${pageNo}`)
      .then((response) => {
        console.log("===load Contacts===", response)
        return response;
      })
      .catch((error) => {
        console.error("=========getContacts error===", error);
      });
    },
    createContactsChatroom: (selectedContacts) => {
      return API.post(`${SERVICE_NAME}/${selectedContacts}/create_chatroom`)
      .then((response) => {
        console.log("==Contacts chatroom created====", response)
        return response;
      })
      .catch((error) => {
        console.error("=========createContactsChatroom error ===", error);
      });
  },
}


export default ContactsService;


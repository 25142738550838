import { Fragment  } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import BackgroundLayer from '@components/Common/Dialogue/Transitions/BackgroundLayer';
import SmallSlideOverLayyer from '@components/Common/Dialogue/Transitions/SmallSlideOverLayyer';
import PropTypes from 'prop-types';
import LibButton from '@components/Common/Button/LibButton';

const TooLongMessage = ({ isOpen, setIsOpen }) => {

  const cancleHandler = () => {
    setIsOpen(false)
  }


  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" id="deleteConfirmationBox" className="relative z-50" onClose={setIsOpen}>

        <BackgroundLayer />
        <SmallSlideOverLayyer>
          <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-md bg-white text-left align-middle shadow-xl transition-all">

            {/*header*/}
            <div className="flex items-start justify-between py-5 px-7 rounded-t osm:py-4.5 osm:pb-0">
              <h3 className="text-xxl font-medium flex-1">
                Your message is too long
              </h3>
            </div>
            {/*body*/}
            <div className="relative py-5 px-7 flex-auto osm:py-2.5">
              <p className="text-secondary text-base leading-relaxed">
                Please edit it down to 1000 characters or split over multiple messages.
              </p>
            </div>
            {/*footer*/}
            <div className="flex gap-6 items-center justify-end py-3 px-7 mt-9 rounded-b bg-b300 font-semibold">
              <LibButton
                extraclass={'bg-primary text-sm'}
                onClick={cancleHandler}>
                Ok
              </LibButton>
            </div>
          </Dialog.Panel>
        </SmallSlideOverLayyer>
      </Dialog>
    </Transition>
  )
}

TooLongMessage.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
}

export default TooLongMessage;
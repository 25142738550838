import React from 'react';
import PropTypes from 'prop-types';

const LibButton = ({ children, extraclass, btntype, ...props }) => {

  return (
    <button
      data-testid="button-default"
      className={`lib_btn flex justify-center items-center px-6 py-2.5 h-9.2 disabled:opacity-50 rounded text-white text-sm font-semibold ${extraclass ? extraclass : 'btn-primary'}`}
      type={btntype ? btntype : 'button'}
      {...props}
    >
      {children}
    </button>
  )
}

LibButton.propTypes = {
  children: PropTypes.string.isRequired,
  extraclass: PropTypes.string.isRequired,
  btntype: PropTypes.string,
}

export default LibButton



// <LibButton
// extraclass={'bg-none'}
// onClick={() => setIsOpen(false)}>
// Close
// </LibButton>
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  visitedPortal: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    //with payload
    setUser(state, action) {
      const user = action.payload;
      if (state.user !== user) {
        state.user = user
      }
    },
    setVisitedPortal(state, action) {
      state.visitedPortal = action.payload
    },
    setLocalToken(state, action) {
      localStorage.setItem('token', action.payload);
    },
    getLocalToken() {
      if (localStorage.getItem('token')) {
        return localStorage.getItem('token');
      }
    }
  },
})

//named export
export const userActions = userSlice.actions;

//default export
export default userSlice;
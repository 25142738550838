import API from '@lib/api';
import { PortalType } from "..";
const SERVICE_NAME = `${PortalType}/teams`;

let TeamService = {
  getTeams: (pageNo = 1) => {
    return API.get(`${SERVICE_NAME}?page=${pageNo}`)
      .then((response) => {
        console.log("===load Teams===", response)
        return response
      })
      .catch((error) => {
        console.error("=========getTeams error===", error);
      });
  },
  createTeamsChatroom: (selectedFacility) => {
    return API.post(`${SERVICE_NAME}/${selectedFacility}/create_chatroom`)
      .then((response) => {
        console.log("== Team Chatroom created====", response)
        return response;
      })
      .catch((error) => {
        console.error("=========createTeamsChatroom error ===", error);
      });
  },
  getTeamsByCurrentChatroomId: (user_id, current_chatroom_id) => {
    return API.get(`${SERVICE_NAME}?user_id=${user_id}&current_chatroom_id=${current_chatroom_id}`)
      .then((response) => {
        console.log("===load TeamsByChatroomId===", response)
        return response
      })
      .catch((error) => {
        console.error("=========getTeamsByCurrentChatroomId error===", error);
      });
  },
}


export default TeamService;


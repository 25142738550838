import { createSlice } from "@reduxjs/toolkit";
import { find, includes, isEmpty, isUndefined, reduce } from "lodash";

const initialState = {
  messagesHistory: [],
};

const messagehistorySlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessageHistory(state, action) {
      state.messagesHistory = action.payload;
    },
    updateOlddataMessageHistory(state, action) {
      const newData = action.payload;
      const ALLMessage = JSON.parse(JSON.stringify(state.messagesHistory))

      const { date_map, messages } = newData[newData.length - 1];

      const _ALLMessage = ALLMessage.map(((el) => {
        if (el.date_map === date_map) {
          // merge new data
          const _messages = [...el?.messages, ...messages]
          el = { ...el, messages: _messages }
        }
        return el
      }))

      state.messagesHistory = _ALLMessage;
    },
    updateMessageHistory(state, action) {
      let ALLMessage = JSON.parse(JSON.stringify(state.messagesHistory))

      const { date_display, date_map } = action.payload;
      const _date_map = ALLMessage[0]?.date_map;

      if (_date_map === date_map) {
        const _f = find(ALLMessage[0].messages, (el) => el.id === action.payload.id)
        if (isUndefined(_f)) {
          ALLMessage[0].messages.unshift(action.payload)
        }
      } else {
        ALLMessage.unshift({
          date_display, date_map, messages: [action.payload]
        })
      }

      state.messagesHistory = ALLMessage;
    },
    
    
  },
})

//named export
export const messagehistoryActions = messagehistorySlice.actions;

//default export
export default messagehistorySlice;
import { createSlice } from "@reduxjs/toolkit";
import {
  concat,
  countBy,
  filter,
  find,
  get,
  includes,
  isEmpty,
  isUndefined,
  map,
  orderBy,
  reduce,
  uniqBy,
} from "lodash";

const initialState = {
  all_chatrooms: [],
  chatrooms_direct: [],
  is_have_new_message_direct: false,
  chatrooms_team: [],
  is_have_new_message_team: false,

  current_chatroom: {},

  current_chatroom_detail: {},
  isChatroomLoading: false,

  selectedTeam: {},
  updateChatroom: false,
  pagination: {},

  isChatroomCreatedByUser: false,
  isCurrentChatroomDeleted: false,
  isCurrentChatroomParticipantsDeleted: false,
  deletedChatroom: {},
  realTimeParticipantsMessages: [],
  realTimeChatroomMessages: [],
};

// const directTypeArr = ['User', 'Customer', 'ClubUser', 'FacilityDetail', 'ClubDetail', 'ClubCompany', 'Group']
// const teamTypeArr = ['Team']

const filterAndResetIndexingAllChatroom = (state, chatrooms, type) => {
  const all_chatrooms = uniqBy(chatrooms, (e) => e.id);
  const __direct_chatroom = orderBy(
    filter(all_chatrooms, (el) => el.chatroom_owner !== "Team"),
    ["id"],
    ["desc"]
  );
  const __team_chatroom = orderBy(
    filter(all_chatrooms, (el) => el.chatroom_owner === "Team"),
    ["id"],
    ["desc"]
  );

  const _direct_chatroom = orderBy(
    __direct_chatroom,
    ["is_get_new_message"],
    ["asc"]
  );
  const _team_chatroom = orderBy(
    __team_chatroom,
    ["is_get_new_message"],
    ["asc"]
  );

  const direct_chatroom = orderBy(_direct_chatroom, ["is_deleted"], ["asc"]);
  const team_chatroom = orderBy(_team_chatroom, ["is_deleted"], ["asc"]);

  const new_message_direct_count = countBy(
    direct_chatroom,
    (el) => el?.is_get_new_message
  );
  const new_message_team_count = countBy(
    team_chatroom,
    (el) => el?.is_get_new_message
  );

  state.all_chatrooms = all_chatrooms;
  state.chatrooms_direct = direct_chatroom;
  state.chatrooms_team = team_chatroom;
  state.is_have_new_message_direct = new_message_direct_count.true >= 1;
  state.is_have_new_message_team = new_message_team_count.true >= 1;
};

const chatroomSlice = createSlice({
  name: "chatroom",
  initialState,
  reducers: {
    // with payload
    setOnlyNewChatrooms(state, action) {
      const new_chatrooms = action.payload.data;
      filterAndResetIndexingAllChatroom(state, new_chatrooms);
    },
    setChatrooms(state, action) {
      const _chatrooms = JSON.parse(JSON.stringify(state.all_chatrooms));
      const new_chatrooms = action.payload.data;
      const all_chatrooms = concat(_chatrooms, new_chatrooms);
      filterAndResetIndexingAllChatroom(state, all_chatrooms);
    },
    resetChatrooms(state) {
      const all_chatrooms = [];
      filterAndResetIndexingAllChatroom(state, all_chatrooms);
    },
    setCurrentChatroom(state, action) {
      sessionStorage.setItem("current_chatroom", JSON.stringify(action.payload));
      state.current_chatroom = action.payload;
      state.isChatroomCreatedByUser = true;
    },
    setPagination(state, action) {
      state.pagination = action.payload;
    },
    setCurrentChatroomDetail(state, action) {
      state.current_chatroom_detail = action.payload;
    },
    setSelectedTeam(state, action) {
      state.selectedTeam = action.payload;
    },
    setUpdateChatRoom(state) {
      state.updateChatroom = !state.updateChatroom;
      state.isCurrentChatroomDeleted = false;
      state.isCurrentChatroomParticipantsDeleted = false;
      state.realTimeParticipantsMessages = [];
      state.realTimeChatroomMessages = [];
    },
    setIsHaveNewMessage(state, action) {
      // DELETE NEW TAG AFTER READ MESSAGE
      const { chatroom_owner, id } = action?.payload?.response;
      if (chatroom_owner.toLowerCase() === "team") {
        const chatrooms_team = JSON.parse(JSON.stringify(state.chatrooms_team));
        const _chatrooms_team = map(chatrooms_team, (el) => {
          if (id === el.id) {
            if (el.is_get_new_message) {
              delete el.is_get_new_message;
              delete el.new_message_count;
            }
            if (el.is_deleted) {
              el.is_deleted = false;
            }
          }
          return el;
        });
        // const team_chatroom = orderBy(_chatrooms_team, ['is_deleted'], ['asc']);

        const new_message_team_count = countBy(
          _chatrooms_team,
          (el) => el?.is_get_new_message
        );
        state.is_have_new_message_team = new_message_team_count.true >= 1;

        state.chatrooms_team = _chatrooms_team;
      } else {
        const chatrooms_direct = JSON.parse(
          JSON.stringify(state.chatrooms_direct)
        );
        const _chatrooms_direct = map(chatrooms_direct, (el) => {
          if (id === el.id) {
            if (el.is_get_new_message) {
              delete el.is_get_new_message;
              delete el.new_message_count;
            }
            if (el.is_deleted) {
              el.is_deleted = false;
            }
          }
          return el;
        });
        const direct_chatroom = orderBy(
          _chatrooms_direct,
          ["is_deleted"],
          ["asc"]
        );

        const new_message_direct_count = countBy(
          _chatrooms_direct,
          (el) => el?.is_get_new_message
        );
        state.is_have_new_message_direct = new_message_direct_count.true >= 1;

        state.chatrooms_direct = direct_chatroom;
      }
    },
    setChatRoomLoading(state, action) {
      state.isChatroomLoading = action.payload;
    },
    updatedChatroomArrayByRedux(state, action) {
      const message = action.payload;

      const _all_chatrooms = JSON.parse(JSON.stringify(state.all_chatrooms));
      const current_chatroom = JSON.parse(
        JSON.stringify(state.current_chatroom)
      );

      const all_chatrooms = map(_all_chatrooms, (el) => {
        // without new
        if (message.chatroom_id === el.id) {
          el = {
            ...el,
            last_message: message.body,
            last_message_sent_by: message.sender_fullname,
            last_message_sender_uuid: message.sender_uuid,
            last_message_sent_date: message.created_at_date,
            last_message_sent_time: message.created_at_time,
            new_message_count: el?.new_message_count
              ? el?.new_message_count + 1
              : 1,
            is_deleted: false,
            is_get_new_message: true,
          };

          // new message arrival only for other members
          // if (!isEmpty(current_chatroom) && message.chatroom_id !== current_chatroom?.id) {
          //   el = { ...el, is_get_new_message: true, is_deleted: false }
          // }
          if (message.chatroom_id === current_chatroom?.id) {
            delete el.is_get_new_message;
          }

          return el;
        } else {
          delete el.is_get_new_message;
        }
        return el;
      });

      const new_msz_chatroom_owner = find(
        all_chatrooms,
        (el) => el.id === message.chatroom_id
      ).chatroom_owner;

      if (new_msz_chatroom_owner === "Team") {
        state.is_have_new_message_team = true;
      } else {
        state.is_have_new_message_direct = true;
      }
      filterAndResetIndexingAllChatroom(state, all_chatrooms);
    },
    setIsChatroomCreatedByUser(state, action) {
      state.isChatroomCreatedByUser = action.payload;
    },
    setFalseIsCurrentChatroomParticipantsDeleted(state) {
      state.isCurrentChatroomParticipantsDeleted = false;
    },

    // setRealTimeTeamListingsMessages(state, action) {
    //   const { alert } = action.payload;
    //   if (alert.action === "TEAMADDED") {
    //     console.log("==This team is updated")
    //   } else if (alert.action === "TEAMDELETED") {
    //     console.log("==This team is deleted")
    //   }

    // },

    setIsCurrentChatroomDeleted(state, action) {
      if (isEmpty(action.payload)) {
        state.isCurrentChatroomDeleted = false;
        state.realTimeChatroomMessages = [];
        state.realTimeParticipantsMessages = [];
      } else {
        const { message, user } = action.payload;
        const { action: act, data } = message;
        // chatInput Box
        const current_chatroom = JSON.parse(
          JSON.stringify(state.current_chatroom)
        );
        const chatroom_uuid =
          act === "ChatroomDeleted" ? data?.uuid : data?.chatroom_uuid;

        state.deletedChatroom = data;

        if (
          !isUndefined(current_chatroom) &&
          current_chatroom?.uuid &&
          chatroom_uuid &&
          user?.session_user_id === data?.participant_id
        ) {
          // if curerent chatroom is delete then remove detail too
          state.isCurrentChatroomDeleted = true;
          state.current_chatroom_detail = {};
        }

        // chatroom
        const realTimeChatroomMessages = JSON.parse(
          JSON.stringify(state.realTimeChatroomMessages)
        );
        const findChatroom = realTimeChatroomMessages.find(
          (el) => el.id === data.id
        );

        // participant
        const realTimeParticipantsMessages = JSON.parse(
          JSON.stringify(state.realTimeParticipantsMessages)
        );
        const findParticipants = realTimeParticipantsMessages.find(
          (el) => el.id === data.id
        );

        if (act === "ChatroomDeleted" && isUndefined(findChatroom)) {
          // state.realTimeChatroomMessages.push({ id: data.id, message: `The ${data.title} is deleted.` })
          state.realTimeChatroomMessages.push({
            id: data.id,
            message: `The Chatroom is deleted.`,
          });
        } else if (
          act === "ParticipantDeleted" &&
          isUndefined(findParticipants)
        ) {
          state.realTimeParticipantsMessages.push({
            id: data.id,
            message: `${data.full_name} is removed.`,
          });
          state.isCurrentChatroomParticipantsDeleted = true;
        }
      }
    },
    setRealTimeMessageTeamDeleted(state, action) {
      const { message, user, visitedPortal } = action.payload;

      if (isEmpty(action.payload) || visitedPortal === "user") {
        state.isCurrentChatroomDeleted = false;
        state.realTimeChatroomMessages = [];
        state.realTimeParticipantsMessages = [];
      } else {
        const { action: act, data } = message;
        // chatInput Box
        const current_chatroom = JSON.parse(
          JSON.stringify(state.current_chatroom)
        );
        const chatroom_id =
          act === "TEAMDELETED"
            ? data?.chatroom_group[0].id
            : data?.customer?.id;

        const idsArr = reduce(
          data?.chatroom_group,
          (a, v) => {
            if (!includes(a, v.id)) {
              a = [...a, v.id];
            }
            return a;
          },
          []
        );

        const memberIdsArr = reduce(
          data?.team?.team_members,
          (a, v) => {
            if (!includes(a, v.id)) {
              a = [...a, v.id];
            }
            return a;
          },
          []
        );

        if (includes(idsArr, current_chatroom?.chatroom_owner_id)) {
          state.deletedChatroom = data;
        }

        if (
          !isUndefined(current_chatroom) &&
          current_chatroom?.chatroom_owner_id &&
          chatroom_id &&
          ((user?.session_user_id === data?.customer?.id &&
            act === "TEAMMEMBERDELETED") ||
            act === "TEAMDELETED")
        ) {
          // if curerent chatroom is delete then remove detail too
          state.isCurrentChatroomDeleted = true;
          state.current_chatroom_detail = {};
        }

        // chatroom
        const realTimeChatroomMessages = JSON.parse(
          JSON.stringify(state.realTimeChatroomMessages)
        );
        const findChatroom = realTimeChatroomMessages.find(
          (el) => el.id === data.id
        );

        // participant
        const realTimeParticipantsMessages = JSON.parse(
          JSON.stringify(state.realTimeParticipantsMessages)
        );
        const findParticipants = realTimeParticipantsMessages.find(
          (el) => el.id === data?.customer?.id
        );

        if (act === "TEAMDELETED" && isUndefined(findChatroom)) {
          // state.realTimeChatroomMessages.push({ id: data.id, message: `The ${data?.name} is deleted.` })
          state.realTimeChatroomMessages.push({
            id: data.id,
            message: `The Chatroom is deleted.`,
          });
        } else if (
          act === "TEAMMEMBERDELETED" &&
          isUndefined(findParticipants) &&
          includes(memberIdsArr, user?.session_user_id)
        ) {
          state.realTimeParticipantsMessages.push({
            id: data?.customer?.id,
            message: `${data?.customer?.full_name} is removed.`,
          });
          state.isCurrentChatroomParticipantsDeleted = true;
        }
      }
    },
  },
});

//named export
export const chatroomActions = chatroomSlice.actions;

//default export
export default chatroomSlice;

/* eslint-disable array-callback-return */
import React from "react";
import LibIconTextButton from "@components/Common/Button/LibIconTextButton";
import { layoutActions } from "@store/layout-slice";
import Utils from "@utils";
import { useDispatch } from "react-redux";
import useDimensions from "@utils/useDimensions";
import LibIconButton from '@components/Common/Button/LibIconButton';
import PropTypes from 'prop-types';
import Loading from "@components/Common/Loading";
import { capitalizeHandler } from "@utils";
import { defaultImageLoad } from "../../../utils";
// import Envelope from "@components/Common/Icons/Envelope";
// import Phone from "@components/Common/Icons/Phone";
function ClubInfo({ deleteGroupChatRoom }) {

  const dispatch = useDispatch()
  const dimentions = useDimensions();
  const chatroomDetail = Utils.getCurrentChatroomDetail();
  const currentChatroom = Utils.getCurrentChatroom();
  const isChatroomLoading = Utils.getCurrentChatroomLoading();
  const isChatroomCreatedByUser = Utils.getIsChatroomCreatedByUser();
  const user = Utils.getCurrentUser();

  const setSidebarOpen = () => {
    dispatch(layoutActions.setRightBar(false))
  }

  return (
    <div className="flex w-full flex-col h-full chat-lists overflow-hidden">
      <div className="listTeamsHeader w-full mb-8 hidden lg:flex">
        <div className="flex w-full items-center justify-between">
          <h3 className="text-lg font-medium text-white lg:text-b900">
            Club Information
          </h3>
          <LibIconButton
            extraclass={'bg-lb iconButton-35 rounded'}
            icon={'close.svg'}
            text={'close'}
            btntype='default'
            onClick={() => setSidebarOpen()}
            iconsize={20}
          />
        </div>
      </div>

      {(() => {
        if (isChatroomLoading) {
          return <Loading />
        } else {
          return (
            <div className="flex w-full lg:flex-col flex-col-reverse h-full chat-lists overflow-hidden">
              <div className="flex-1 team-lists-container h-full flex flex-col overflow-hidden overflow-y-auto_ pt-16 lg:pt-0">
                <div className="">
                  <div className="osm:mb-0 clubLogo">
                    {/* Not getting image use this code */}
                    {chatroomDetail?.chatroom_profile_picture ? <img className="image rounded-full server-image" src="/images/epic_games.png" alt="club name" /> :
                      <div className="bg-secondary rounded-full static-info-icon flex justify-center items-center">
                        <img data-testid="currentRecieverImage"
                          className="w-11 h-11"
                          aria-hidden
                          src={`/images/icons/${defaultImageLoad(currentChatroom, user)}`}
                          alt="header-logo" />
                      </div>}
                  </div>
                  <div className="clubName text-xl font-medium text-center text-b900 block">
                    {capitalizeHandler(chatroomDetail?.title)}
                  </div>
                </div>
                <div className="flex-1 overflow-y-auto">
                  <div className="clubAddress text-xs font-medium text-center mb-1.5 text-b400 block">
                    {chatroomDetail?.metadata?.company_detail?.full_address}
                  </div>
                  <div className="clubType flex justify-center items-center mb-6">
                    <ul className="flex oml:gap-1 gap-0.5 flex-wrap justify-center">
                      {chatroomDetail?.metadata?.company_detail?.categories?.map((el, index) =>
                        <li key={index}>
                          <span className="bg-brown100 text-white px-2 py-1 uppercase font-semibold pills">
                            {el}
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="clubContactInfo flex justify-center items-start gap-2.5 flex-col">
                    <div className="clubEmail infoSec bg-lb mmd:bg-b300 lg:bg-mainbgColor rounded-md py-2 px-2.5 text-center w-full">
                      <div className="overflow-hidden flex items-center">
                        <span className="icon mr-3.6">
                          <span className="fixIcon envelop rounded w-[2.25rem] h-[2.25rem] bg-primary">
                            <img src="/images/icons/envelope.svg" alt="Envelope" className="w-5.5 h-5.5" />
                          </span>
                        </span>
                        <div className="mobileAndEmail break-keep break-words text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden text-b900">
                          {chatroomDetail?.metadata?.company_detail?.email ? chatroomDetail?.metadata?.company_detail?.email : 'Not available'}
                        </div>
                      </div>
                    </div>
                    <div className="clubMobile infoSec bg-lb mmd:bg-b300 lg:bg-mainbgColor rounded-md py-2 px-2.5 text-center w-full">
                      <div className="overflow-hidden flex items-center">
                        <span className="icon mr-3.6">
                          <span className="fixIcon phone rounded w-[2.25rem] h-[2.25rem] bg-primary">
                            <img src="/images/icons/phone.svg" alt="Phone" className="w-5.5 h-5.5" />
                          </span>
                        </span>
                        <div className="mobileAndEmail break-keep break-words text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden text-b900">
                          {chatroomDetail?.metadata?.company_detail?.phone ? chatroomDetail?.metadata?.company_detail?.phone : 'Not available'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="listTeamsFooter clubInfo">
                <div className="flex items-center justify-between lg:justify-end ">
                  <LibIconTextButton
                    extraclass={'lg:hidden text-white'}
                    icon={'back-arrow-white.svg'}
                    text={'Back'}
                    btntype={'plain'}
                    labeltext={'Back'}
                    labelClass='!text-lg'
                    onClick={setSidebarOpen}
                    iconsize={15}
                  />
                  {isChatroomCreatedByUser && <LibIconTextButton
                    extraclass={'text-white lg:text-b75 !gap-1'}
                    icon={(dimentions?.width > 1024) ? 'bin-black.svg' : 'bin-white.svg'}
                    text={'Delete Chat'}
                    btntype={'plain'}
                    labeltext={'Delete chat'}
                    labelClass='!font-semibold'
                    iconsize={21}
                    disabled={!isChatroomCreatedByUser}
                    onClick={deleteGroupChatRoom}
                  />}

                </div>
              </div>

            </div>
          )
        }
      })()}
    </div>
  );
}

ClubInfo.propTypes = {
  deleteGroupChatRoom: PropTypes.func.isRequired
}

export default ClubInfo;

import React, { Fragment, useEffect, useState } from 'react'
import ConfirmBox from "@components/Common/Dialogue/ConfirmBox";
import { chatroomActions } from '@store/chatroom-slice';
import { layoutActions } from "@store/layout-slice";
import { useDispatch } from "react-redux";
import { errorHandler } from '@utils/errorHandler';
import viewHelper from '@utils/viewHelper';
import { toast } from 'react-toastify';
import ChatroomService from "@services/common/chatroom/ChatroomService";
import Utils from "@utils";
import { includes, isEmpty } from 'lodash';

const HOCdeleteChatroom = (OriginalComponent) => {
  const NewComponent = (props) => {

    let [isOpen, setIsOpen] = useState(false)
    let [isTrue, setIsTrue] = useState(false)

    const dispatch = useDispatch();

    const currentChatroom = Utils.getCurrentChatroom();
    const messagesHistory = Utils.getMessagesHistory();
    const isChatroomCreatedByUser = Utils.getIsChatroomCreatedByUser();
    const user = Utils.getCurrentUser();

    const deleteGroupChatRoom = () => {
      setIsOpen(true)
      // deleteGroupChatRoomHandler()
    }

    // useEffect(() => {
    //   if (isTrue) {
    //     ChatroomService.deleteAllTypeChatroom(currentChatroom.uuid)
    //       .then((response) => {
    //         if (response?.status === 200) {
    //           dispatch(layoutActions.setRightBar(false))
    //           dispatch(layoutActions.setChatSection(false))
    //           dispatch(chatroomActions.setUpdateChatRoom())
    //           dispatch(chatroomActions.setCurrentChatroom({}))
    //           toast(
    //             viewHelper.returnToastMarkup({
    //               title: 'Success',
    //               message: response.data.message,
    //               type: toast.TYPE.SUCCESS
    //             }),
    //             {
    //               autoClose: 3000,
    //               pauseOnHover: false,
    //             }
    //           );
    //         } else {
    //           toast.error(errorHandler(response));
    //         }
    //       })
    //       .catch(function (error) {
    //         toast.error(errorHandler(error));
    //       });
    //   }
    // }, [isTrue])
    // return "delete";

    const deleteGroupChatRoomHandler = () => {
      const getOptionalKey = () => {

        // includes(["ClubCompany", "Company"], currentChatroom.chatroom_owner)
        // && user.session_user_type === 'Customer'
        if (
          // ALL GROUP/TEAM HAVE ONLY DELETE FEATURE
          isEmpty(messagesHistory) ||
          includes(["Group", "Team"], currentChatroom.chatroom_owner)
        ) {
          return 'delete'
        } else {
          return currentChatroom?.is_deleted ? "activate" : "deactivate"
        }
      }
      if (isChatroomCreatedByUser) {
        ChatroomService.deleteAllTypeChatroom(currentChatroom.uuid, getOptionalKey())
          .then((response) => {
            if (response?.status === 200) {
              dispatch(layoutActions.setRightBar(false))
              dispatch(layoutActions.setChatSection(false))
              dispatch(chatroomActions.setUpdateChatRoom())
              dispatch(chatroomActions.setCurrentChatroom({}))
              toast(
                viewHelper.returnToastMarkup({
                  title: 'Success',
                  message: response.data.message,
                  type: toast.TYPE.SUCCESS
                }),
                {
                  autoClose: 3000,
                  pauseOnHover: false,
                }
              );
            } else {
              toast.error(errorHandler(response));
            }
          })
          .catch(function (error) {
            toast.error(errorHandler(error));
          });
      } else {
        //  TODO I'll check if I forgot to deactivate the button
        //  For another user who did not create CURRENT chatroom
        toast.error('You are not authorized for this operation');
      }
    }

    return (<Fragment>
      <OriginalComponent
        deleteGroupChatRoom={deleteGroupChatRoom}
      />
      <ConfirmBox
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        deleteGroupChatRoomHandler={deleteGroupChatRoomHandler}
      />
    </Fragment>)
  }


  return NewComponent

}

export default HOCdeleteChatroom

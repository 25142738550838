import { get, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import viewHelper from './viewHelper';

// toast.dismiss();
// === for success====
// toast.success("You have logged in successfully.");
// === for error=====
// toast.error(errorHandler(error));
// === how errorHandler works =======

function defaultError() {
    return 'There was some Error. Please try again after some time';
}

function fiveHundredError() {
    return 'Something went wrong. Refresh the page or Try again later';
}

function fourHundredError() {
    return 'Page Not Found. Please try something else';
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function errorHandler(error) {
    let errors = [];
    let toastId = null;
    // toast.dismiss();
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(get(error.response, 'status'));
        console.log(get(error, 'response.data.message', []));
        // console.log(get(error.response, 'status') === 500);
        // console.log(!isEmpty(get(error, "response.data.error", [])))
        // console.log(error.response.headers);
        // if (get(error.response, "status") === 500) {

        //   //for devise response, as it sends response in error
        // }
        if (!isEmpty(get(error, 'response.data.message', []))) {
            toastId = toast(
                viewHelper.returnToastMarkup({
                    title: error.response.status == 422 ? "Warning" : "Error",
                    message: get(error, 'response.data.message', []),
                    type: error.response.status == 422 ? toast.TYPE.WARNING : toast.TYPE.ERROR,
                }),
                { autoClose: 1000, pauseOnHover: false }
            );

        } else if (!toast.isActive(toastId)) {
            toastId = toast(
                viewHelper.returnToastMarkup({
                    title: 'Something Went Wrong',
                    message: !isEmpty(get(error, 'response.data.error', []))
                        ? error.response.data.error
                        : fiveHundredError(),
                    type: toast.TYPE.ERROR,
                }),
                { autoClose: 1000, pauseOnHover: false }
            );
        }
    } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
        // return defaultError.toString();
        if (!toast.isActive(toastId)) {
            toastId = toast(
                viewHelper.returnToastMarkup({
                    title: 'Unable To Connect',
                    message: fiveHundredError(),
                    type: toast.TYPE.ERROR,
                }),
                { autoClose: 1000, pauseOnHover: false }
            );
        }
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        // return defaultError.toString();
        if (!toast.isActive(toastId)) {
            toastId = toast(
                viewHelper.returnToastMarkup({
                    title: error.message,
                    message: defaultError(),
                    type: toast.TYPE.WARNING,
                }),
                { autoClose: 1000, pauseOnHover: false }
            );
        }
    }
}
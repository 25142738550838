/* eslint-disable import/no-anonymous-default-export */

import React, { Suspense, lazy, memo, useEffect } from "react";
import Chat from "@components/Chat/Chat";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import useDimensions from "@utils/useDimensions";

import Loading from "@components/Common/Loading";
import RightDialogueLg from "@components/Common/Dialogue/RightDialogueLg";
import RightDialogueLgGroup from "@components/Common/Dialogue/RightDialogueLgGroup";
import ChatSectionMobile from "@components/Common/Dialogue/ChatSectionMobile";
import SidebarRight from "@components/Common/Dialogue/SidebarRight";
import { layoutActions } from '@store/layout-slice';

const LeftSection = lazy(() => import("@components/Layout/LeftSection"));
const RightSection = lazy(() => import("@components/Layout/RightSection"));

function Home({ cable }) {
  // console.log('home')
  const dispatch = useDispatch();
  const dimentions = useDimensions();
  const rightSidebarBreakPoint = 1330;// 1440;

  const { groupTogglerType, chatByPortal, groupTogglerPopup, newChatPopup, rightBarOpen } = useSelector((state) => ({ ...state.layout, shallowEqual }))

  // useEffect(() => {
  //   dispatch(layoutActions.setGroupTogglerType({}));
  //   dispatch(layoutActions.setGroupTogglerPopup(false))

  //   // new_chatPopup
  //   dispatch(layoutActions.setNewChatPopup(false));
  //   dispatch(layoutActions.setChatByPortal({}));
  // }, [dimentions])

  return (
    <div data-testid="homeComp" id="homeComp" className="chatSection chat_container mx-auto">
      <div className="flex h-full bg-mainbgColor" id="homeCompInner">

        {newChatPopup && chatByPortal?.value !== '' ?
          <RightDialogueLg />
          : null}

        {groupTogglerPopup && groupTogglerType?.value !== '' ?
          <RightDialogueLgGroup />
          : null}

        {dimentions?.width <= 1024 &&
          <ChatSectionMobile cable={cable} />
        }

          <SidebarRight
          isDisplay={dimentions?.width <= rightSidebarBreakPoint}
          cable={cable} />
        
        <div id="chatroomDasktop" className="flex flex-1 flex-col overflow-hidden p-0 lg:p-7.5">
          <div className="flex gap-5 h-full overflow-hidden">

            {/* Static sidebar for desktop */}
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div id="sidebarDasktop" className="leftSection h-full lg:p-6 rounded-md left-width-431 bg-white lg:bg-secondbgColor">
              <LeftSection cable={cable} />
            </div>
            {/* Static sidebar for desktop end */}

            {/* Start main area*/}
              <div id="selectedChatRoom" className="centerSection w-full bg-white lg:bg-secondbgColor p-0 lg:p-5 rounded-md hidden lg:flex relative">
                <main className="z-0 flex-1">
                  <Chat cable={cable} />
                </main>
              </div>
            {/* End main area */}

            {/* Start secondary column (hidden on smaller screens) */}
            {/* {rightBarOpen && dimentions?.width >= rightSidebarBreakPoint && */}
            <div
              style={{ display: rightBarOpen && dimentions?.width >= rightSidebarBreakPoint ? 'flex' : 'none' }}
              id="listbarDasktop"
              className="rightSection h-full width-431 bg-white lg:bg-secondbgColor p-6 rounded-md hidden lg:flex">
              <RightSection cable={cable} />
            </div>
            {/* } */}
            {/* End secondary column */}
          </div>
        </div>

      </div>
    </div>
  );
}

Home.propTypes = {
  cable: PropTypes.object.isRequired
}
export default memo(Home);

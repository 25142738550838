import API from '@lib/api';
import { isUndefined } from 'lodash';

let MessageService = {
  getMessageHistory: function (link) {
    if (!isUndefined(link)) {
      return API.get(`${link}`)
        .then((response) => {
          console.log("=== Message History ===", response);
          return response;
        })
        .catch((error) => {
          console.error("=========getMessageHistory error===", error);
        });
    }
  },
  createUserMessage: function (user_id, payload) {
    return API.post(`/users/${user_id}/create_message`, payload)
      .then((response) => {
        console.log("=== create_message ===", response);
        return response;
      })
      .catch((error) => {
        console.error("=========createUserMessage error===", error);
      });
  },
};

export default MessageService;

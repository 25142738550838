import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import TeamsList from '@components/Information/Teams'
import ClubInfo from '@components/Information/ClubInfo'
import FacilityInfo from '@components/Information/FacilityInfo';
import CustomerInfo from '@components/Information/CustomerInfo';
import GroupInfo from '@components/Information/GroupInfo';
// import { layoutActions } from "@store/layout-slice";
import Utils from "@utils";
import { useDispatch } from "react-redux";
// import ConfirmBox from "@components/Common/Dialogue/ConfirmBox";
// import { chatroomActions } from '@store/chatroom-slice';
// import ChatroomService from "@services/common/chatroom/ChatroomService";
import { includes } from 'lodash';
// import { errorHandler } from '@utils/errorHandler';
// import viewHelper from '@utils/viewHelper';
// import { toast } from 'react-toastify';
import HOCdeleteChatroom from '../../ExtraComponent/HOCdeleteChatroom';
import { chatroomReactType } from '@utils';

const RightSection = ({ deleteGroupChatRoom }) => {
	const dispatch = useDispatch()
	const currentChatroom = Utils.getCurrentChatroom();
	const user = Utils.getCurrentUser();
	// let [isOpen, setIsOpen] = useState(false)
	// let [isTrue, setIsTrue] = useState(false)

	// const deleteGroupChatRoom = () => {
	// 	setIsOpen(true)
	// }

	// useEffect(() => {
	// 	if (isTrue) {
	// 		ChatroomService.deleteAllTypeChatroom(currentChatroom.uuid)
	// 			.then((response) => {
	// 				if (response?.status === 200) {
	// 					dispatch(layoutActions.setRightBar(false))
	// 					dispatch(layoutActions.setChatSection(false))
	// 					dispatch(chatroomActions.setUpdateChatRoom())
	// 					dispatch(chatroomActions.setCurrentChatroom({}))
	// 					toast(
	// 						viewHelper.returnToastMarkup({
	// 							title: 'Success',
	// 							message: response.data.message,
	// 							type: toast.TYPE.SUCCESS
	// 						}),
	// 						{
	// 							autoClose: 3000,
	// 							pauseOnHover: false,
	// 						}
	// 					);
	// 				} else {
	// 					toast.error(errorHandler(response));
	// 				}
	// 			})
	// 			.catch(function (error) {
	// 				toast.error(errorHandler(error));
	// 			});
	// 	}
	// }, [isTrue])

	// ClubUser

	{/* customer : >>
			Club > "ClubCompany"
			facility  > "Company"
			group > "Group"
			team > "Team"

			club : >>
			contacts > "ClubContact"
			customers >  "ClubUser" 
			facility > "Company"
			
			facility : >>
			contacts > "Customer"
			customers >  "User" 
			club >  "ClubCompany" 
*/}

	return (
		<aside id="teamList" className="w-full flex h-full xl:flex xl:flex-col">
			{/* ['Team', 'User', 'Customer', 'ClubCompany'. 'Group', 'Company']  */}

			{/* customer : >>
			Club > "ClubCompany"
			facility  > "Company"
			group > "Group"
			team > "Team"

			club : >>
			contacts > "ClubContact"
			customers >  "ClubUser" 
			facility > "Company"
			
			facility : >>
			contacts > "Customer"
			customers >  "User" 
			club >  "ClubCompany" 

			*/}

			{chatroomReactType(currentChatroom, user) === "CUSTOMERINFO"
				? <CustomerInfo
					deleteGroupChatRoom={deleteGroupChatRoom}
				/>
				: null}
			{chatroomReactType(currentChatroom, user) === "FACILITYINFO"
				? <FacilityInfo
					deleteGroupChatRoom={deleteGroupChatRoom}
				/>
				: null}
			{chatroomReactType(currentChatroom, user) === "CLUBINFO"
				? <ClubInfo
					deleteGroupChatRoom={deleteGroupChatRoom}
				/>
				: null}

			{chatroomReactType(currentChatroom, user) === 'TEAMINFO'
				? <TeamsList
					deleteGroupChatRoom={deleteGroupChatRoom}
				/>
				: null}
			{chatroomReactType(currentChatroom, user) === 'GROUPINFO'
				? <GroupInfo
					deleteGroupChatRoom={deleteGroupChatRoom}
				/>
				: null}

		</aside>
	)
}

RightSection.propTypes = {
	// cable: PropTypes.object.isRequired
	deleteGroupChatRoom: PropTypes.func.isRequired
}

export default HOCdeleteChatroom(RightSection)
